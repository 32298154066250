import React from 'react';
import { withRouter } from 'react-router';
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonButton, IonBadge, IonIcon, IonMenuToggle } from '@ionic/react';
import { basket, chevronDown, personOutline, scan, search } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import BackButton from '../backButton';
import { checkBackgroundColor, forwardTo, getDefaultRoute, getRouteClassName, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import { StrongText, Title } from '../common';
import { ReactComponent as Scan } from '../../assets/images/scan-icon.svg';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger.svg';

import { logout } from '../../store/actions';

const StaticHeader = ({ dispatch, __, auth, showLoginIcon, onTitleClickHandler, history, onSearchHandler, backHandler, clientProfile, navConfig, showHamburger, headerIconColors, clientStyles, hasSearchIcon, searchActive, headerWithTitle, title, disableHeaderLogo, showLogoutButton }) => {
	// const logo_image = clientProfile.logo_image
	//style={{ '--background': isWebConfig() ? '' : `#FFF url(${logo_image}) no-repeat 50% 50%/auto 90%` }
	const currentPath = history.location.pathname;
	const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
	const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
	const pagesWithoutBackButton = getConfig().general.routesWithoutBackButton.indexOf(currentPath) !== -1;
	const headerImageUrl = getConfig().headerImage ? getConfig().headerImage.headerImageUrl : null;
	const headerImageSize = getConfig().headerImage ? getConfig().headerImage.headerImageSize : null;
	const routeClassName = getRouteClassName(currentPath, navConfig);
	let defaultIconsColor = '';
	if (clientStyles?.theme !== 'custom') {
		defaultIconsColor = clientStyles?.theme == 'dark' ? 'white' : 'dark';
	} else {
		defaultIconsColor = clientStyles?.colors?.['--okx-background-color'] ? checkBackgroundColor(clientStyles?.colors['--okx-background-color']) : 'dark';
	}

	let logoMain;
	if (headerImageUrl) {
		logoMain = headerImageUrl;
	} else if (headerIconColors) {
		if (headerIconColors === 'white') {
			logoMain = clientProfile.logo_image_light || clientProfile.logo_image || '';
		} else if (headerIconColors === 'dark') {
			logoMain = clientProfile.logo_image || '';
		}
	} else {
		if (defaultIconsColor === 'white') {
			logoMain = clientProfile.logo_image_light || clientProfile.logo_image || '';
		} else if (defaultIconsColor === 'dark') {
			logoMain = clientProfile.logo_image || '';
		}
	}
	return (
		<>
			{hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
				<IonHeader className={`static ${routeClassName} ${getConfig().general.dashboardWithTiles ? 'colored-header' : 'white-header'}`}>
					<IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}>
						<IonButtons slot="start">
							{pagesWithoutBackButton || showHamburger ? (
								<IonMenuToggle>
									<IonButton color={headerIconColors ? headerIconColors : defaultIconsColor} button clear >
										<Hamburger className={headerIconColors ? `ion-color-${headerIconColors}` : `ion-color-${defaultIconsColor}`} />
									</IonButton>
								</IonMenuToggle>
								// <IonMenuButton color={headerIconColors ? headerIconColors : defaultIconsColor} />
							) : (
								<>
									<BackButton backHandler={backHandler} iconColor={headerIconColors ? headerIconColors : defaultIconsColor} />
								</>
							)}
						</IonButtons>
						{!headerWithTitle ? (
							<>
								{
									headerImageUrl ? (

										<IonButtons className="image-button-resizable-wrapper" color="primary" >
											<img src={logoMain}
												style={{
													maxWidth: headerImageSize ? headerImageSize : 'auto',
													height: 'auto',
													display: isWebConfig() ? 'none' : 'block'
												}}
												className="image-button-resizable"
												color="primary-shade"
												onClick={() => disableHeaderLogo ? null : forwardTo(getConfig().general.defaultRoute ? getConfig().general.defaultRoute : getDefaultRoute(navConfig).path)}
											/>
										</IonButtons>
									) : (
										<IonButtons className="image-button-wrapper" color="primary">
											<IonButton
												style={{
													backgroundImage: !isWebConfig() && !headerWithTitle ? `url(${logoMain})` : '',
													backgroundSize: '80%',
													backgroundPosition: 'center',
													backgroundRepeat: 'no-repeat',

												}}
												className="image-button"
												color="primary-shade"
												onClick={() => disableHeaderLogo ? null : forwardTo('/dashboard')}
											/>
										</IonButtons>
									)
								}
							</>
						) : (
							<IonButtons color="transparent" className="header-title" onClick={() => (onTitleClickHandler ? onTitleClickHandler() : disableHeaderLogo ? null : forwardTo('/dashboard'))}>

								<Title className={`${headerIconColors}-header-title ${onTitleClickHandler ? 'small-header-title' : ''}`}>
									{title} {onTitleClickHandler ? <IonIcon icon={chevronDown} /> : ''}
								</Title>
							</IonButtons>
						)}
						<IonButtons slot="end" className={`${searchActive ? 'button-active' : ''} ${showLogoutButton ? 'header-logout-button' : ''}`}>
							{showLogoutButton ?
								<div className="logout-btn" onClick={() => dispatch(logout())}>
									<StrongText className="block ">{__('Sign out')}</StrongText>

								</div> : <>
									{hasSearchIcon ? (
										<>
											<IonButton className="header-search" color={headerIconColors ? headerIconColors : defaultIconsColor} button clear onClick={() => onSearchHandler()}>
												<IonIcon slot="icon-only" icon={search} />
											</IonButton>
										</>
									) : (
										<>
											{showLoginIcon && !auth?.loggedIn ? (
												<IonButton
													onClick={() => forwardTo('/login')}
													style={{
														border: `1px solid var(--ion-color-${headerIconColors ? headerIconColors : defaultIconsColor})`,
														borderRadius: '50%',
														padding: isWebConfig() ? '1px' : '6px',
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<IonIcon color={headerIconColors ? headerIconColors : defaultIconsColor} icon={personOutline} />
												</IonButton>
											) : (
												<>
													{Basket.itemsCount() > 0 ? (
														<>
															{!Basket.is_gift ? (
																<IonButton
																	color={headerIconColors ? headerIconColors : defaultIconsColor}
																	className="basket-button"
																	onClick={() => forwardTo('/order-summary')}
																	size={24}
																>
																	<div>
																		<div className="basket-icon-wrapper">
																			<IonBadge slot="end" color="primary" className="badge">
																				{Basket.itemsCountAll()}
																			</IonBadge>
																			<Icon icon={basket} classname="icon" color={headerIconColors ? headerIconColors : defaultIconsColor} />
																		</div>
																	</div>
																</IonButton>
															) : (
																<>
																	<IonButton
																		color={headerIconColors ? headerIconColors : defaultIconsColor}
																		className="basket-button"
																		onClick={() => forwardTo('/gift-voucher', { hasEdit: true })}
																		size={24}
																	>
																		<div>
																			<div className="basket-icon-wrapper">
																				<IonBadge slot="end" color="primary" className="badge">
																					{Basket.itemsCountAll()}
																				</IonBadge>
																				<Icon icon={basket} classname="icon" color={headerIconColors ? headerIconColors : defaultIconsColor} />
																			</div>
																		</div>
																	</IonButton>
																</>
															)}
														</>
													) : !authPages && !isWebConfig() ? (
														getConfig().flags.hasLoyalty ? (
															<IonButton color={headerIconColors ? headerIconColors : defaultIconsColor} button clear onClick={() => forwardTo('/loyalty', { tab: 'scan' })}>
																<Scan className={headerIconColors ? `ion-color-${headerIconColors}` : `ion-color-${defaultIconsColor}`} />
															</IonButton>
														) : null
													) : null}
												</>
											)}
										</>
									)}
								</>}
						</IonButtons>
					</IonToolbar>
				</IonHeader>
			) : null}
		</>
	);
};

const stateToProps = (store) => {
	const { orders } = store;
	const { clientProfile, navConfig, clientStyles } = store.common;
	const { auth } = store.profile;

	return {
		basketUpdated: orders.basketUpdated,
		clientProfile,
		navConfig,
		clientStyles,
		auth,
	};
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));

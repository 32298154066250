import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import LibStripe from '../../lib/stripe';
import { getConfig } from '../../appConfig';
import { PaymentRequestButtonElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { showToast, loading } from '../../store/actions';

import Basket from '../../lib/basket';
import { SEND_GIFT_VOUCHER, SET_BILL_STATUS } from '../../store/constants';
import { isDefined } from '../../lib/utils';
import BlockSeparator from '../../components/blockSeparator';
import api from '../../lib/api';

const clientName = getConfig().general.clientName;

const { setSelectedCard } = Basket;

export const Result = ({ children }) => <div className="result">{children}</div>;

export const ErrorResult = ({ children }) => <div className="error">{children}</div>;

const NotAvailableResult = () => (
  <Result>
    {/* <p style={{ textAlign: 'center' }}>
      Please add card to your browser.
		</p> */}
    {window.location.protocol !== 'https:' && (
      <p style={{ textAlign: 'center' }}>
        Try using{' '}
        <a href="https://ngrok.com" target="_blank" rel="noopener noreferrer">
          {' '}
          ngrok{' '}
        </a>{' '}
        to view this demo over https.
      </p>
    )}
  </Result>
);
const ELEMENT_OPTIONS = {
	style: {
		paymentRequestButton: {
			type: 'default',
			theme: 'dark',
		},
	},
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canMakePayment: isDefined(this.props.canMakePayment) ? this.props.canMakePayment : false,
      showBlockSeparator: isDefined(this.props.showBlockSeparator) ? this.props.showBlockSeparator : false,
      hasCheckedAvailability: false,
      errorMessage: null,
      // paymentMethod: null
    };
  }

  async componentDidUpdate(prevProps) {
    const { stripe } = this.props;
    if (this.props.billTotal !== prevProps.billTotal && this.paymentRequest) {
      this.updatePaymentRequest();
    }
    if (prevProps.stripe !== this.props.stripe) {
      this.createPaymentRequest(stripe);
    }
  }
  async updatePaymentRequest() {
    const config = await api.getFrontEndAppConfig();
    const clientName = config?.front_end_app_config?.general?.clientName;
    this.paymentRequest.update({
      total: Basket.getItemsForWebPay(clientName, this.props.isBillPay),
    });
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }
  async createPaymentRequest(stripe) {
    const { dispatch, __, profile } = this.props;
    this.paymentRequest = stripe.paymentRequest({
      country: Basket.getCountry().toUpperCase(),
      currency: Basket.getSelectedCurrency(),
      total: Basket.getItemsForWebPay(clientName),
    });
    this.setState({ paymentWebType: this.paymentRequest._queryStrategy[0] });
    const that = this;
    this.paymentRequest.on('paymentmethod', function (ev) {
      if (that.clientSecret) {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        stripe
          .confirmCardPayment(
            that.clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: true },
          )
          .then(function (confirmResult) {
            dispatch(loading(false));

            if (confirmResult.error) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              ev.complete('fail');
              dispatch(showToast(__(LibStripe.failed_message), 'danger'));
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete('success');
              if (that.giftVoucherData) {
                dispatch({ type: SEND_GIFT_VOUCHER, data: that.giftVoucherData });
              }
              if (that.resetBasket) {
                that.resetBasket(that.giftVoucherData);
              }
              if (that.isBillPay) {
                dispatch({ type: SET_BILL_STATUS, billStatus: confirmResult.paymentIntent });
              }
              dispatch(showToast(__(LibStripe.success_message), 'success'));
              // Check if the PaymentIntent requires any actions and if so let Stripe.js
              // handle the flow. If using an API version older than "2019-02-11" instead
              // instead check for: `paymentIntent.status === "requires_source_action"`.
              if (confirmResult.paymentIntent.status === 'requires_action') {
                // Let Stripe.js handle the rest of the payment flow.
                stripe.confirmCardPayment(that.clientSecret).then(function (result) {
                  if (result.error) {
                    // The payment failed -- ask your customer for a new payment method.
                  } else {
                    // The payment has succeeded.
                  }
                });
              } else {
                // The payment has succeeded.
              }
            }
          });
      } else {
        dispatch(loading(false));
      }
    });

    this.paymentRequest.on('cancel', function () {
      setSelectedCard(profile.cardToken);
      dispatch(loading(false));
      // handle cancel event
    });

    const canMakePaymentRes = await this.paymentRequest.canMakePayment();
    if (canMakePaymentRes) {
      this.setState({ canMakePayment: true, hasCheckedAvailability: true });
    } else {
      this.setState({ canMakePayment: false, hasCheckedAvailability: true });
    }
  }

  render() {
    const {
      canMakePayment,
      hasCheckedAvailability,
      errorMessage,
      paymentMethod,
      paymentWebType,
      showBlockSeparator,
      hasError 
    } = this.state;
    return (
      <>
        {hasError ? (
          <>
            <NormalText>ERROR</NormalText>
          </>) : <>
          <form>
            {canMakePayment && (
              <PaymentRequestButtonElement
                onClick={() => {
                  this.props.dispatch(loading(true));

                  if (this.props.isBillPay) {
                    Basket.createBillPay('webPayment', paymentWebType, (clientSecret, resetBasket, isBillPay) => {
                      this.setState({ clientSecret: clientSecret });
                      this.clientSecret = clientSecret;
                      this.resetBasket = resetBasket;
                      this.isBillPay = true;
                      this.giftVoucherData = false;

                      // this.paymentRequest.show()
                    });
                  } else {
                    Basket.createOrder('webPayment', paymentWebType, (clientSecret, resetBasket, giftVoucherData) => {
                      this.setState({ clientSecret: clientSecret });
                      this.clientSecret = clientSecret;
                      this.resetBasket = resetBasket;
                      this.giftVoucherData = giftVoucherData;
                      this.isBillPay = false;

                      // this.paymentRequest.show()
                    });
                  }
                  this.setState({
                    errorMessage: 'You can only use the PaymentRequest button once. Refresh the page to start over.',
                  });
                }}
                options={{
                  ...ELEMENT_OPTIONS,
                  paymentRequest: this.paymentRequest,
                }}
              />
            )}
            {!canMakePayment && hasCheckedAvailability && <NotAvailableResult />}
            {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>}
          </form>
          {canMakePayment && showBlockSeparator ? (<BlockSeparator text='Or' />) : null}
        </>}
      </>
    );
  }
}

const InjectedCheckoutForm = ({ clientSecret, dispatch, __, location, profile, isBillPay, billTotal }) => (
  <ElementsConsumer>
    {({ stripe }) => <CheckoutForm billTotal={billTotal} isBillPay={isBillPay} stripe={stripe} clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} />}
  </ElementsConsumer>
);


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// console.log('Stripe.getStripeInstance()1', Stripe.getStripeInstance())
// const stripePromise = loadStripe('pk_test_SfLlfP41LhHVIG2vrA95ZrFt')


const CheckoutPay = ({ clientSecret, dispatch, __, location, profile, isBillPay = false, billTotal = 0 }) => {
  const stripePromise = loadStripe(LibStripe.getStripeInstance()._apiKey);
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm isBillPay={isBillPay} clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} billTotal={billTotal} />
    </Elements>
  );
};
export default CheckoutPay;

import { IonButton, IonInput, IonItem } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NormalText, SmallText, Spacer, Title } from '../../components/common';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { isEmptyObject } from '../../lib/utils';
import Layout from '../../components/layout';
import { withRouter } from 'react-router';
import Loading from '../../components/spinner';
import './index.css';
import api from '../../lib/api';

const { SelectOption } = Mobiscroll;

class GiftBalance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			voucherId: '',
			voucherCode: '',
			voucherBalance: null,
		};
	}
	componentDidMount() { }
	checkBalance = async () => {
		try {
			const { voucherCode, voucherId } = this.state;
			const voucher = await api.checkGiftVoucherBalance({ voucher_code: voucherCode, voucher_id: voucherId });
			this.setState({ voucherBalance: voucher.data });
		} catch (err) {
			this.setState({ voucherBalance: { error: true } });
		}
	};
	render() {
		const { __ } = this.props;
		const { voucherBalance } = this.state;
		return (
			<Loading>
				<Layout showHamburger headerWithTitle title={__('Check balance')} hideSecondToolbar={true} color="transparent" scrollY={false} backHandler={this.backHandler}>
					<div className="absolute-content scrollable-y">
						<Title className="web-only">{__('Check balance')}</Title>

						<NormalText className="block">{__('If your voucher does not include a security code, please check in-store')}</NormalText>
						<Spacer size={1} />
						<div className="input-field-container">
							<NormalText>{__('Enter voucher ID')}</NormalText>

							<IonItem lines="none" className="input-field-wrapper">
								<IonInput
									onIonInput={(e) => this.setState({ voucherId: e.target.value })}
									type="text"
									pattern="text"
									inputmode="text"
									value={this.state.voucherId}
									placeholder={__('Voucher ID')}
								></IonInput>
							</IonItem>
						</div>
						<div className="input-field-container">
							<NormalText>{__('Enter security code')}</NormalText>

							<IonItem lines="none" className="input-field-wrapper">
								<IonInput
									onIonInput={(e) => this.setState({ voucherCode: e.target.value })}
									type="text"
									pattern="text"
									inputmode="text"
									value={this.state.voucherCode}
									placeholder={__('Security code')}
								></IonInput>
							</IonItem>
						</div>
						{!isEmptyObject(voucherBalance) && (
							<>
								<Spacer size={1} />
								<div className={`box-content vocher-balance-result ${voucherBalance.error ? 'vocher-balance-result-error' : ''}`}>
									{voucherBalance.error ? (
										<>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												stroke-width="2"
												stroke="currentColor"
												fill="none"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
												<path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5"></path>
												<path d="M19 22v.01"></path>
												<path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
												<path d="M3 7l9 6l9 -6"></path>
											</svg>
											<NormalText>{__('Voucher not found')}</NormalText>
											<SmallText>{__('Please check the details and try again')}</SmallText>
										</>
									) : (
										<>
											<div>
												<NormalText className="block primary-color">{`${Basket.formatPrice(voucherBalance?.points_value / 100)} ${__('eGift Voucher')}`} </NormalText>
												<SmallText className="block">{`${__('Voucher ID:')} ${voucherBalance?.id}`}</SmallText>
												<SmallText className="block">{`${__('Security code:')} ${voucherBalance?.redeem_code}`}</SmallText>
												<SmallText className="block">{`${__('Expiry date:')} ${moment(voucherBalance?.expiry_date)?.format('DD/MM/YY')}`} </SmallText>
												<Spacer size={1} />
												{voucherBalance?.value_remaining !== voucherBalance?.points_value && (
													<>
														<SmallText className="block primary-color">{__('Original balance:')}</SmallText>
														<SmallText className="block">{Basket.formatPrice(voucherBalance?.points_value / 100)}</SmallText>
													</>
												)}
												<SmallText className="block primary-color">{__('Remaining balance:')}</SmallText>
												<SmallText className="block">{Basket.formatPrice(voucherBalance?.value_remaining / 100)}</SmallText>
											</div>
											<div className="pill">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													stroke-width="2"
													stroke="currentColor"
													fill="none"
													stroke-linecap="round"
													stroke-linejoin="round"
												>
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
													<path d="M9 12l2 2l4 -4"></path>
												</svg>
												{voucherBalance?.status}
											</div>
										</>
									)}
								</div>
							</>
						)}

						<Spacer size={1} />
						<IonButton disabled={this.state.voucherId == '' || this.state.voucherCode == ''} onClick={() => this.checkBalance()} expand="block" color="primary">
							{__('Check balance')}
						</IonButton>
					</div>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	return {};
};

export default withRouter(withTranslation(connect(stateToProps)(GiftBalance)));

import {  IonIcon,  } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { withTranslation } from '../../lib/translate';
import { validateProfileData } from '../../lib/utils';
import { setModal } from '../../store/actions';
import { NormalText } from '../common';
import './index.css';
import { alertCircle, checkmarkCircle } from 'ionicons/icons';

const ValidateButton = ({ __, dispatch, profile, auth }) => {
  const isAuth = auth.loggedIn;
  const valid = validateProfileData(profile).isValid;
  if (!getConfig().appType.hasEmailValidationEnabled) {
    return null;
  }
  return (
    <>
      {isAuth && !valid ? (
        <>
          <div onClick={() => dispatch(setModal('isVerfiedModalOpen', true))} className=" validate-button pointer">
            <IonIcon color='danger' icon={alertCircle} />
            <NormalText className="validate-status">{__("Account must be verified to redeem.")}</NormalText>
          </div>
        </>
      ) : isAuth && valid ? (
        <div className=" validate-button valid">
          <IonIcon color='success' icon={checkmarkCircle} />
          <NormalText className="validate-status">{__('Account verified.')}</NormalText>
        </div>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { isVerfiedModalOpen, profile, auth } = store.profile;
  return {
    isVerfiedModalOpen,
    profile,
    auth,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(ValidateButton)));

import React, { useState } from 'react';
import { IonIcon, IonInput, IonButton, IonLabel } from '@ionic/react';
import { eye, eyeOff, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import './index.css';
import { ReactComponent as Eye } from '../../assets/images/eye.svg';
import { ReactComponent as EyeOff } from '../../assets/images/eye-off.svg';


const PasswordInput = ({ value: propValue, label, labelColor, onIonChange, ...rest }) => {
  const [value, setValue] = useState(propValue || '');
  const [show, setShow] = useState(false);

  const togglePass = (show) => {
    setShow(show);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onIonChange) {
      onIonChange(e);
    }
  };

  return (
    <div className="okx-password-wrapper">
      <div className="okx-password-holder">
        {label && (
          <IonLabel position="floating" color={labelColor}>
            {label}
          </IonLabel>
        )}
        <IonInput
          {...rest}
          className="okx-password"
          type={show ? 'text' : 'password'}
          pattern="password"
          value={value}
          onIonInput={handleChange}
          onIonBlur={handleChange}
        />
      </div>
      <div
      style={{width:'30px',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}}
        onTouchStart={() => togglePass(true)}
        onTouchEnd={() => togglePass(false)}
        onMouseDown={() => togglePass(true)}
        onMouseUp={() => togglePass(false)}
      >
        {show ? <Eye style={{width:'22px',height:'22px'}} /> : <EyeOff style={{width:'22px',height:'22px'}}/>}
      </div>

    </div>
  );
};

export default PasswordInput;

import { IonActionSheet, IonButton, IonCol, IonGrid, IonIcon, IonInput, IonRow, isPlatform } from '@ionic/react';
import { close, search } from 'ionicons/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, SmallText, Spacer, StrongText, Subtitle } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { deepCopy, forwardTo, getDistanceUnit, goBack, isDefined, isEmptyObject, isTimeAvaibleInMenu, isTimeInRange } from '../../lib/utils';
import location from '../../assets/images/map-pin-filled.svg';
import { getIkentooMenusForLocation, setScrollTop } from '../../store/actions';
import './index.css';
import OrderList from './orderList';
import TileOrderList from './tileOrderList';
import moment from 'moment';
import { checkSnoozedTimes, formatDataForTime, isStoreOpened } from '../clickAndCollect';
import { ReactComponent as LocationIcon } from '../../assets/images/distance.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/acute.svg';
import { ReactComponent as TableIcon } from '../../assets/images/picnic-table.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit-icon.svg';

class OrderPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCategory: 0,
			ikentooMenu: null,
			originalMenu: null,
			menuRefs: null,
			categoriesPositionTop: [],
			selectedMenu: [],
			searchItemsValue: '',
			searchOpened: false,
			selectRestaurantsModalOpen: false
		};
		this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
	}

	setIkentooMenu = (menu) => {
		this.setState(
			{
				ikentooMenu: menu || (Basket.itemsCount() === 0 && isEmptyObject(this.props.ikentooMenu)) ? this.props.defaultMenu : this.props.ikentooMenu,
				originalMenu: menu || (Basket.itemsCount() === 0 && isEmptyObject(this.props.ikentooMenu)) ? this.props.defaultMenu : this.props.ikentooMenu,
			},
			() => {
				let menuRefs = this.menuRefs(this.state.ikentooMenu);
				this.setState({ menuRefs });
			},
		);
		if (this.state.searchItemsValue !== '') {
			this.filterMenuItems(this.state.searchItemsValue);
		}
	};
	componentDidMount() {
		const { scrollTop } = this.props;
		this.setIkentooMenu();
		this.setState({
			selectedMenuType: getConfig().menuDisplayType,
		});
		if (this.props.location.state) {
			this.setState({ selectedMenu: this.props.location.state.selectedMenu });
		}
		setTimeout(() => {
			const item = document.querySelector('.order-list-items');
			if (item && !isEmptyObject(this.props.location.state)) {
				item.scrollTop = scrollTop;
				if (!isPlatform('ios')) {
					item.scrollBy({ top: 1, behavior: 'smooth' });
				}
			}
		}, 100);
	}

	shouldComponentUpdate(nextProps) {
		const prevMenuName = (Basket.itemsCount() === 0 && isEmptyObject(this.props.ikentooMenu) ? nextProps.defaultMenu : nextProps.ikentooMenu || {}).menuName;
		const currentMenuName = (Basket.itemsCount() === 0 && isEmptyObject(this.props.ikentooMenu) ? this.props.defaultMenu : this.props.ikentooMenu || {}).menuName;
		if (prevMenuName !== currentMenuName || nextProps.restaurantsUpdated !== this.props.restaurantsUpdated) {
			this.setIkentooMenu();
		}
		return true;
	}
	menuRefs = (menuRefs) => {
		return menuRefs && menuRefs.menuEntryGroups
			? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
				let name = `${value.name}_${index}`;
				acc[name] = React.createRef();
				return acc;
			}, {})
			: {};
	};

	scrollTo = (name) => this.state.menuRefs[name].current.scrollIntoView();
	selectCategoryOnScroll = (e) => {
		const scrollTop = Math.abs(e.target.scrollTop);
		const heading = document.querySelector('.order-header-content-wrapper');
		if (heading && scrollTop > 10) {
			heading.style.display = 'none';
		} else {
			heading.style.display = 'block';
		}
		e.preventDefault();
		const positionTop = scrollTop + document.querySelector('.order-categories').clientHeight;
		const { menuRefs } = this.state;
		const positions = Object.keys(menuRefs).map((key) => {
			return menuRefs[key].current.offsetTop;
		});
		let selectCategory = 0;
		positions.forEach((item, i) => {
			if (item <= positionTop) {
				selectCategory = i;
			}
		});
		if (document) {
			const catDiv = document.querySelector('.order-categories-inner');
			if (catDiv && catDiv.childNodes && catDiv.childNodes[selectCategory]) {
				catDiv.childNodes[selectCategory].scrollIntoView({
					behavior: 'smooth',
					inline: 'start',
				});
			}
		}
		this.props.dispatch(setScrollTop(scrollTop));
		this.setState({ selectedCategory: selectCategory, scrollTop: scrollTop });
	};

	backHandler = () => {
		let menuDisplayType = this.state.selectedMenuType;
		const selectedMenus = this.state.selectedMenu || [];
		if (menuDisplayType !== 1 && selectedMenus.length > 0) {
			const items = this.state.selectedMenu;
			items.pop();
			this.setState({ selectedMenu: items });
		} else {
			const path = Basket.getDeliveryOption()?.route ?? getDefaultRoute(this.props.navConfig).path;
			forwardTo(path, { saveOrderData: true });
		}
	};
	hideBack = () => {
		let menuDisplayType = getConfig().menuDisplayType;
		const selectedMenus = this.state.selectedMenu || [];
		if (menuDisplayType !== 1 && selectedMenus.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	selectMenu = (index) => {
		const selectedItem = index;
		const currentCategories = this.state.selectedMenu ? [...this.state.selectedMenu] : [];
		currentCategories.push(selectedItem);
		this.setState({ selectedMenu: currentCategories });
	};

	parseCategories = (data) => {
		const { menuDisplayType, ikentooMenu } = data;
		const selectedMenu = data.selectedMenu || [];

		let categories;
		if (menuDisplayType !== 1) {
			categories = !isEmptyObject(ikentooMenu) ? ikentooMenu : [];
			if (selectedMenu.length > 0) {
				categories = categories.menuEntryGroups[selectedMenu[0]];
				for (let i = 1; i < selectedMenu.length; i++) {
					categories = categories.menuEntry[selectedMenu[i]];
				}
			}
		} else {
			categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
		}
		return categories;
	};
	filterMenu(menu) {
		if (menu && menu.menuEntryGroups) {
			menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
			return menu;
		}
		return menu;
	}
	filterIkentooMenuItems(items) {
		let filteredItems = [];
		items.forEach((item) => {
			if (!item.menuEntry) {
				// break recursion when arrive to the product
				if (Basket.getProductName(item, this.props.profile).toLowerCase().includes(`${this.state.searchItemsValue.toLowerCase()}`)) {
					filteredItems.push(item);
					return [item];
				} else {
					return [];
				}
			} else {
				const len = (item.menuEntry || []).length;
				if (len > 0) {
					const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
					if (newFilteredItems.length > 0) {
						item.menuEntry = newFilteredItems;
						filteredItems.push(item);
					}
				}
			}
		});

		return filteredItems;
	}
	filterMenuItems = (value) => {
		this.setState({ searchItemsValue: value }, () => {
			if (getConfig().menuDisplayType !== 1) {
				if (this.state.selectedMenuType !== 1 && this.state.searchItemsValue !== '') {
					this.setState({ selectedMenuType: 1 }, () => {
						const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
						const filtered = this.filterMenu(menu);
						this.setState({ ikentooMenu: filtered }, () => {
							let menuRefs = this.menuRefs(this.state.ikentooMenu);
							this.setState({ menuRefs });
						});
					});
				} else if (this.state.selectedMenuType === 1 && this.state.searchItemsValue === '') {
					this.setState({ selectedMenuType: getConfig().menuDisplayType }, () => {
						const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
						const filtered = this.filterMenu(menu);
						this.setState({ ikentooMenu: filtered }, () => {
							let menuRefs = this.menuRefs(this.state.ikentooMenu);
							this.setState({ menuRefs });
						});
					});
				} else {
					const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
					const filtered = this.filterMenu(menu);
					this.setState({ ikentooMenu: filtered }, () => {
						let menuRefs = this.menuRefs(this.state.ikentooMenu);
						this.setState({ menuRefs });
					});
				}
			} else {
				const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
				const filtered = this.filterMenu(menu);
				this.setState({ ikentooMenu: filtered }, () => {
					let menuRefs = this.menuRefs(this.state.ikentooMenu);
					this.setState({ menuRefs });
				});
			}
		});
	};

	formatDayName = (__, name) => {
		if (name.includes('Today')) {
			name = 'Today'.toLowerCase();
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow'.toLowerCase();
		} else {
			name = Basket.getOrderDate();
		}
		return name;
	};
	drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
		let dateName = this.formatDayName(
			__,
			moment(Basket.getOrderDate(), 'dddd Do MMMM').calendar(null, {
				sameDay: '[Today]',
				nextDay: '[Tomorrow]',
			}),
		);
		const selectedRestaurant = Basket.getRestaurant();
		if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					{this.props.driverBookingPending ? (
						<div>
							<div>
								<div>
									<NormalText>{__('Booking delivery...')}</NormalText>
								</div>
								<StrongText>{__('Allocating an available driver for your order...')}</StrongText>
							</div>
							<div>
								<IonSpinner name={'circular'} style={{ height: '30px', width: '30px' }} />
							</div>
						</div>
					) : (
						<>
							{this.props.driverBookingMessage ? (
								<div>
									<div>
										<div>
											<NormalText>{__('Booking delivery...')}</NormalText>
										</div>
										<StrongText>{__('Driver successfully booked')}</StrongText>
									</div>
									<div style={{ display: 'flex' }}>
										<IonIcon style={{ height: '35px', width: '35px' }} color="success" icon={checkmarkCircleOutline} />
									</div>
								</div>
							) : (
								<>
									<div>
										<NormalText>{__('Delivery to')}</NormalText>
									</div>
									<NormalText>
										{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')} {Basket.getOrderTime()}
									</NormalText>
								</>
							)}
						</>
					)}
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
			return (
				<>
			
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<TableIcon />{' '}

						<NormalText>{__('Table number')}</NormalText>
						<StrongText className="bold">{Basket.getTableNumber()}</StrongText>
					</div>
				</div>
				</>

			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-collection') {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<TimeIcon />{' '}

						<NormalText>{__('Collection time')}</NormalText>
					</div>
					<NormalText className="bold">
						{Basket.additional_data?.named_time_slots_data ? (
							<>
								{dateName} {'at'} {Basket.additional_data?.named_time_slots_data}
							</>
						) : (
							<>
								{dateName} {'at'} {Basket.getOrderTime('h:mm a')}
							</>
						)}
					</NormalText>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-at-counter') {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}
						<StrongText>{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<TimeIcon />{' '}
						<NormalText>{__('Collection time')}</NormalText>
						<NormalText className="bold">{__('ASAP')}</NormalText>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'browse-menu' && selectedRestaurant) {
			return (
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />{' '}

						<StrongText >{selectedRestaurant.name} &nbsp;</StrongText>
						{selectedRestaurant.browse_menu_json_time_selector > 1 ? (
							<div onClick={() => forwardTo(`/browse-menu?bl_id=${selectedRestaurant.business_location_id}`)}>
								<NormalText className="bold link underlined">{`${__('View other menus')}`}</NormalText>
							</div>
						) : null}
					</div>
					<div>
						<NormalText className="block">{__(selectedRestaurant.address)}</NormalText>
					</div>
					{selectedRestaurant?.distance && (
						<div className="distance-wrapper">
							<IonIcon className="location-icon" icon={location}></IonIcon>
							<NormalText className="block bold-description click-collect-small-description">
								{selectedRestaurant.distance < 1 ? selectedRestaurant.distance.toFixed(1) : selectedRestaurant.distance.toFixed(0)} {__(getDistanceUnit())}
							</NormalText>
						</div>
					)}
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-delivery') {
			return (
				<div className="box-content order-header-content">
					<div>
						<LocationIcon />{' '}

						<StrongText className="bold">{Basket.getRestauranName()}</StrongText>
					</div>
					<div>
						<NormalText>{__('Delivery to')}</NormalText>
					</div>
					<NormalText className="block">
						{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode}
					</NormalText>
					<NormalText className="block">
						{' '}
						{dateName} {__('at')} {Basket.getOrderTime('h:mm a')}
					</NormalText>
				</div>
			);
		} else {
			return (
				<>
				
				<div className={`box-content order-header-content ${Basket.has_multi_basket ? 'multi-order-heder-content' : ''}`}>
					<div>
						<LocationIcon />

						<StrongText>{Basket.getRestauranName()}</StrongText>
						<EditIcon style={{margin:0}} onClick={()=>forwardTo('/click-and-collect',{saveBasket:true})}/>

					</div>
					<div>
						<TimeIcon />{' '}

						<NormalText>{__('Collection time')}</NormalText>
						<NormalText className="bold">{Basket.getOrderTime('h:mm a')}</NormalText>
					</div>
				</div>
				</>

			);
		}
	};
	onSearchHandler = () => {
		this.setState({ searchOpened: !this.state.searchOpened });
	};
	selectMultiVenueRestaurant = (restaurant) => {
		this.setState({ selectedMenu: [] }, () => {
			Basket.setRestaurant(restaurant);
			if (Basket.order_type == 'collection') {
				const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(restaurant.business_location_id, {
							pickTime,
							json_time_selector: restaurant ? restaurant.json_time_selector : [],
						}),
					);
				});
			} else if (Basket.order_type == 'table') {
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(restaurant.business_location_id, {
							pickTime: 'asap',
							json_time_selector: restaurant ? restaurant.table_json_time_selector : [],
						}),
					);
				});
			} else if (Basket.order_type == 'pick-up-at-counter') {
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(
							restaurant.business_location_id,
							{
								pickTime: 'asap',
								json_time_selector: restaurant ? restaurant.pick_up_at_counter_json_time_selector : [],
							},
							false,
						),
					);
				});
			} else if (Basket.order_type == 'browse-menu') {
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(
							restaurant.business_location_id,
							{
								pickTime: 'asap',
								json_time_selector: restaurant ? restaurant.browse_menu_json_time_selector : [],
							},
							restaurant.browse_menu_json_time_selector.length === 1 ? false : true,
						),
					);
				});
			} else if (Basket.order_type == 'charter-delivery') {
				const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(restaurant.business_location_id, {
							pickTime,
							json_time_selector: restaurant ? restaurant.charter_delivery_times_json : [],
						}),
					);
				});
			} else if (Basket.order_type == 'scheduled-delivery') {
				const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(restaurant.business_location_id, {
							pickTime,
							json_time_selector: [
								{
									menuId: restaurant?.active_menus.scheduled_delivery?.menu_id,
									availability: Object.keys(restaurant?.active_menus.scheduled_delivery?.slots).map((slot) => {
										if (restaurant?.active_menus.scheduled_delivery?.slots[slot].start_time) {
											return {
												d: weekdays[slot],
												start: restaurant?.active_menus.scheduled_delivery?.slots[slot].start_time,
												end: restaurant?.active_menus.scheduled_delivery?.slots[slot].end_time,
											};
										}
									}),
								},
							],
						}),
					);
				});
			} else if (Basket.order_type == 'scheduled-collection') {
				const pickTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
				this.setState({ selectRestaurantsModalOpen: false }, () => {
					this.props.dispatch(
						getIkentooMenusForLocation(restaurant.business_location_id, {
							pickTime,
							json_time_selector: [
								{
									menuId: restaurant?.active_menus.scheduled_collection?.menu_id,
									availability: Object.keys(restaurant?.active_menus.scheduled_collection?.slots).map((slot) => {
										if (restaurant?.active_menus.scheduled_collection?.slots[slot].start_time) {
											return {
												d: weekdays[slot],
												start: restaurant?.active_menus.scheduled_collection?.slots[slot].start_time,
												end: restaurant?.active_menus.scheduled_collection?.slots[slot].end_time,
											};
										}
									}),
								},
							],
						}),
					);
				});
			}
		});
	};
	titleClickHandler = () => {
		this.setState({ selectRestaurantsModalOpen: !this.state.selectRestaurantsModalOpen });
	};
	formatDataForSelect = () => {
		let formatedStores = [];
		this.props.restaurants.forEach((store) => {
			const orderType = Basket.order_type;
			let currentDT = moment.unix(Basket.collection_time).tz(getConfig().timezone);
			if (orderType == 'table') {
				currentDT = moment().tz(getConfig().timezone);
			}
			let minDT = currentDT;
			const storeOpened = isStoreOpened(store);
			const snoozedTimes = checkSnoozedTimes(store, orderType);
			let slotsAvailable = [];
			store.isEnabled = storeOpened;
			if (orderType == 'collection') {
				let additionalSettings = store && store.additional_delivery_settings ? store.additional_delivery_settings : {};
				additionalSettings = additionalSettings[Basket.getDeliveryOption()?.id];
				if (additionalSettings && additionalSettings?.asap === false && additionalSettings?.timePicker === false) {
					slotsAvailable = isTimeAvaibleInMenu(store.json_time_selector, currentDT);
				} else {
					const timeData = formatDataForTime(store, minDT, store.id, false, false, snoozedTimes, this.props.scheduled);
					slotsAvailable =
						timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed'
							? false
							: timeData[0].value.toLowerCase() == 'asap'
								? true
								: moment(moment(minDT).format('HH:mm'), 'HH:mm').isSameOrAfter(moment(timeData[0].value, 'HH:mm'));
				}
				store.opened = slotsAvailable;

				store.isEnabled = storeOpened && slotsAvailable;
				if (store.is_published && store.can_collection_order) {
					formatedStores.push(store);
				}
			} else if (orderType == 'table') {
				const slotsAvailable = isTimeAvaibleInMenu(store.table_json_time_selector, currentDT);
				store.isEnabled = storeOpened && slotsAvailable;
				store.opened = slotsAvailable;
				if (store.is_published && store.can_table_order) {
					formatedStores.push(store);
				}
			} else if (orderType == 'pick-up-at-counter') {
				if (store.is_published && store.can_pick_up_at_counter) {
					const slotsAvailable = isTimeAvaibleInMenu(store.pick_up_at_counter_json_time_selector, currentDT, moment().tz(getConfig().timezone));
					store.isEnabled = storeOpened && slotsAvailable;
					store.opened = slotsAvailable;

					formatedStores.push(store);
				}
			} else if (orderType == 'browse-menu') {
				if (store.is_published && store.can_browse_menu) {
					formatedStores.push(store);
					store.isEnabled = true;
				}
			} else if (orderType == 'charter-delivery') {
				if (store.is_published && store.can_charter_delivery_order) {
					const slotsAvailable = isTimeAvaibleInMenu(store.charter_delivery_times_json, currentDT, moment().tz(getConfig().timezone));
					store.isEnabled = storeOpened && slotsAvailable;
					store.opened = slotsAvailable;
					formatedStores.push(store);
				}
			} else if (orderType == 'scheduled-collection') {
				if (store.is_published && store.can_scheduled_collection_order) {
					const collectionTime = currentDT.format('DD/MM/YYYY');
					const daysAhead = store?.active_menus?.scheduled_collection?.days_ahead + 1 || 8;
					const timezone = getConfig().timezone;
					const unavailableDates = store?.active_menus?.scheduled_collection.unavailable_dates.map((slot) => moment(slot, 'DD/MM/YYYY').format('DD/MM/YYYY')) ?? [];
					const futureDays = Array(daysAhead)
						.fill('')
						.map((day, i) => moment().tz(timezone).add(i, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('DD/MM/YYYY'))
						.filter((day) => !unavailableDates.includes(day));

					let slotsAvailable = true;
					if (!futureDays.includes(collectionTime)) {
						slotsAvailable = false;
					} else {
						const collectionDate = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('d');
						let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
						const dayName = days[collectionDate];
						const slots = store?.active_menus?.scheduled_collection.slots[dayName];
						const collectionHour = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
						if (!slots) {
							slotsAvailable = false;
						}
						slotsAvailable = isTimeInRange(collectionHour, slots.start_time, slots.end_time);
					}
					store.isEnabled = storeOpened && slotsAvailable;
					store.opened = slotsAvailable;
					formatedStores.push(store);
				}
			} else if (orderType == 'scheduled-delivery') {
				if (store.is_published && store.can_scheduled_delivery_order) {
					const collectionTime = currentDT.format('DD/MM/YYYY');
					const daysAhead = store?.active_menus?.scheduled_delivery?.days_ahead + 1 || 8;
					const timezone = getConfig().timezone;
					const unavailableDates = store?.active_menus?.scheduled_delivery.unavailable_dates.map((slot) => moment(slot, 'DD/MM/YYYY').format('DD/MM/YYYY')) ?? [];
					const futureDays = Array(daysAhead)
						.fill('')
						.map((day, i) => moment().tz(timezone).add(i, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('DD/MM/YYYY'))
						.filter((day) => !unavailableDates.includes(day));

					let slotsAvailable = true;
					if (!futureDays.includes(collectionTime)) {
						slotsAvailable = false;
					} else {
						const collectionDate = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('d');
						let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
						const dayName = days[collectionDate];
						const slots = store?.active_menus?.scheduled_delivery.slots[dayName];
						const collectionHour = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
						if (!slots) {
							slotsAvailable = false;
						}
						slotsAvailable = isTimeInRange(collectionHour, slots.start_time, slots.end_time);
					}
					store.isEnabled = storeOpened && slotsAvailable;
					store.opened = slotsAvailable;
					formatedStores.push(store);
				}
			}
		});
		return formatedStores;
	};
	render() {
		const { __, scrollTop } = this.props;
		const { selectedCategory, ikentooMenu, selectedMenu } = this.state;
		if (ikentooMenu?.ikentooMenuId !== this.props.ikentooMenu?.ikentooMenuId && this.props.ikentooMenu.ikentooMenuId) {
			this.setState({ ikentooMenu: this.props.ikentooMenu, originalMenu: this.props.ikentooMenu })
		}
		let menuDisplayType = this.state.selectedMenuType;
		let categories = this.parseCategories({ selectedMenu, ikentooMenu, menuDisplayType });
		let formatedStores = this.formatDataForSelect();
		if (Basket.has_multi_basket && getConfig().flags.geolocationDisabled) {
			const stores = deepCopy(formatedStores);
			let openedRestaurants = stores.filter((store) => store.opened);
			let closedRestaurants = stores.filter((store) => !store.opened);
			openedRestaurants.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
			openedRestaurants.sort((a, b) => a.sort_order - b.sort_order);
			closedRestaurants.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
			closedRestaurants.sort((a, b) => a.sort_order - b.sort_order);
			formatedStores = [...openedRestaurants, ...closedRestaurants];
		}
		return (
			<Loading>
				<Layout
					searchActive={this.state.searchOpened}
					onSearchHandler={this.onSearchHandler}
					hasSearchIcon
					headerWithTitle
					title={Basket.has_multi_basket && Basket.getRestaurant() ? Basket.getRestauranName() : Basket.getDeliveryOption()?.label ?? __('Menu')}
					noPadding
					scrollY={false}
					backHandler={this.backHandler}
					showHamburger={Basket.has_multi_basket ? false : selectedMenu ? selectedMenu.length === 0 : true}
					color="transparent"
					onTitleClickHandler={Basket.has_multi_basket && Basket.getRestaurant() ? this.titleClickHandler : null}

				>
					<IonActionSheet
						isOpen={this.state.selectRestaurantsModalOpen}
						onDidDismiss={() => this.setState({ selectRestaurantsModalOpen: false })}
						header={__('Select restaurant')}
						buttons={[
							...formatedStores.map((store) => {
								if (store.name == Basket.getRestauranName()) {
									return {
										cssClass: 'action-sheet-active',
										text: store.name,
										handler: () => this.setState({ selectRestaurantsModalOpen: false }),
									};
								} else {
									return {
										text: store.name,
										cssClass: !store.isEnabled ? 'disabled-element disabled-opacity' : '',
										handler: () => this.selectMultiVenueRestaurant(store),
									};
								}
							}),

							{
								text: __('Cancel'),
								role: 'cancel',
								data: {
									action: 'cancel',
								},
							},
						]}
					></IonActionSheet>
					{this.state.searchOpened && (
						<div className="search-box">
							<div>
								<IonIcon color="secondary" icon={search}></IonIcon>
								<IonInput
									placeholder={__('Search products')}
									value={this.state.searchItemsValue}
									onIonInput={(e) => {
										this.filterMenuItems(e.target.value);
									}}
								/>
								<IonIcon onClick={() => this.setState({ searchItemsValue: '', selectedMenu: [] })} className="reset-icon" color="secondary" icon={close}></IonIcon>
							</div>
						</div>
					)}
					{!isDefined(ikentooMenu) ? null : menuDisplayType === 1 ? (
						<div className="segment-holder">
							<div className="order-header-content-wrapper ">
								{ikentooMenu?.menuName?.indexOf('Default') === -1 && (
									<>
										<div className="default-padding">{this.drawContentHeader(__, Basket.getDeliveryOption(), Basket.getOrderType(), Basket.getDeliveryAddress())}</div>
									</>
								)}
							</div>
							{getConfig().frenchDisclaimer?.disclaimerImage && (
								<>
									<div className="order-disclaimer-wrapper">
										<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
									</div>
								</>
							)}
							<div className="order-categories">
								{categories.length > 0 ? (
									<div className="order-categories-inner">
										{categories?.map((category, index) => {
											return (
												<IonButton
													key={index}
													size="small"
													fill="clear"
													className={selectedCategory === index ? 'category-button active' : 'category-button'}
													onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
												>
													<StrongText>{__(category?.name)}</StrongText>
												</IonButton>
											);
										})}
									</div>
								) : (
									<>
										<Spacer />
										<NormalText className="no-items-message">{__('No items found')}</NormalText>
									</>
								)}
							</div>
							<div className="order-content">
								<OrderList
									scrollTopPosition={scrollTop}
									selectCategoryOnScroll={this.selectCategoryOnScroll}
									category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
									menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
								/>
								{Basket.itemsCount() > 0 ? (
									<div className="view-order-button">
										<IonButton className='large-button' onClick={() => forwardTo('/order-summary')} expand="block">
											<IonGrid>
												<IonRow>
													<IonCol>
														{Basket.itemsCountAll()}&nbsp;
														{Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
													</IonCol>
													<IonCol>{__('View basket')}</IonCol>

													<IonCol>{Basket._getTotal()}</IonCol>
												</IonRow>
											</IonGrid>
										</IonButton>
									</div>
								) : null}
							</div>
						</div>
					) : (
						<>
							<div className={`segment-holder grid-menus ${Basket.itemsCount() > 0 ? 'have-items-in-basket' : ''}`}>
								<div className="order-header-content-wrapper ">
									{ikentooMenu?.menuName?.indexOf('Default') === -1 && (
										<>
											<div className="default-padding">{this.drawContentHeader(__, Basket.getDeliveryOption(), Basket.getOrderType(), Basket.getDeliveryAddress())}</div>
											<Spacer size={1} />
										</>
									)}
								</div>
								{getConfig().frenchDisclaimer?.disclaimerImage && (
									<>
										<div className="order-disclaimer-wrapper">
											<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
										</div>
									</>
								)}

								<div className="order-categories">
									<TileOrderList
										selectMenu={(index) => {
											this.selectMenu(index);
										}}
										category={categories}
										selectedMenu={this.state.selectedMenu}
									></TileOrderList>
								</div>
								<div className="order-content">
									{Basket.itemsCount() > 0 ? (
									<div className="">
											<IonButton onClick={() => forwardTo('/order-summary')} expand="block" color="primary">
												<IonGrid>
													<IonRow>
														<IonCol>
															{Basket.itemsCountAll()}&nbsp;
															{Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
														</IonCol>
														<IonCol>{__('View basket')}</IonCol>

														<IonCol>{Basket._getTotal()}</IonCol>
													</IonRow>
												</IonGrid>
											</IonButton>
										</div>
									) : null}
								</div>
							</div>
						</>
					)}
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { auth, clientProfile } = state.common;
	const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;
	const { scrollTop } = state.orders;
	return {
		auth,
		restaurants: restaurants || [],
		ikentooMenu: ikentooMenu || {},
		defaultMenu: defaultMenu || {},
		basketUpdated: state.orders.basketUpdated,
		restaurantsUpdated,
		scrollTop,
		clientProfile,
	};
};

export default connect(stateToProps)(withTranslation(OrderPage));

import React from 'react';
import { IonAlert, IonButton, IonIcon } from '@ionic/react';
import { add, remove } from 'ionicons/icons';
import { isDefined } from '../../lib/utils';
import './index.css';
import { NormalText, StrongText } from '../common';
import { withTranslation } from '../../lib/translate';

class Incrementer extends React.Component {
  state = {
    quantity: isDefined(this.props.quantity)
      ? this.props.quantity
      : this.props.step
        ? this.props.step * 1
        : 1,
    step: this.props.step || 1,
    allowNegative: this.props.allowNegative !== undefined ? this.props.allowNegative : true,
    minLimit: isDefined(this.props.minLimit) ? this.props.minLimit : null,
    maxLimit: isDefined(this.props.maxLimit) ? this.props.maxLimit : null,
    enableLastItemAlert: isDefined(this.props.enableLastItemAlert) ? this.props.enableLastItemAlert : false,
    showLastItemAlert: false
  };
  componentDidUpdate(prevProps) {
    if (prevProps.quantity !== this.props.quantity) {
      this.setState({ quantity: this.props.quantity });
    }
    if (prevProps.maxLimit !== this.props.maxLimit) {
      this.setState({ maxLimit: this.props.maxLimit });
    }
  }

  decrease = () => {
    const { quantity, step, allowNegative, minLimit, enableLastItemAlert } = this.state;
    const { onDecrease, onUpdate, data } = this.props;
    let _step = step;
    if (quantity > 0 && quantity % _step !== 0) {
      _step = quantity % _step;
    }
    let newQuantity = quantity - _step;
    if (!allowNegative && newQuantity < 0) {
      newQuantity = 0;
    }
    if (isDefined(minLimit) && newQuantity < minLimit) {
      newQuantity = minLimit;
    }
    if (enableLastItemAlert && newQuantity === 0) {
      this.setState({ showLastItemAlert: true });
    } else {
      this.setState({ quantity: newQuantity }, () => {
        if (onDecrease) {
          onDecrease(newQuantity);
        }
        if (onUpdate) {
          onUpdate(newQuantity, data);
        }
      });
    }
  };

  increase = () => {
    const { quantity, step, maxLimit } = this.state;
    const { onIncrease, onUpdate, data } = this.props;
    let newQuantity = quantity + step;
    if (isDefined(maxLimit) && newQuantity > maxLimit) {
      newQuantity = maxLimit;
    }
    this.setState({ quantity: newQuantity }, () => {
      if (onIncrease) {
        onIncrease(newQuantity);
      }
      if (onUpdate) {
        onUpdate(newQuantity, data);
      }
    });
  };
  render() {
    const { quantity, enableLastItemAlert, showLastItemAlert,minLimit } = this.state;
    const { children, unit, note, disabled, size, onUpdate, onDecrease, data, __ } = this.props;

    return (
      <div className="incrementer">
        <IonButton
          disabled={quantity <= minLimit ? true : isDefined(disabled) ? disabled : false}
          className="incrementer-decrease"
          onClick={this.decrease}
        >
          <IonIcon size={size} icon={remove} />
        </IonButton>
        <div className={'incrementer-quantity' + (!note && !children ? '-solo' : '')}>
          <StrongText className="incrementer-quantity-value">
            {JSON.parse(JSON.stringify(quantity))}
          </StrongText>
          {unit ? <span className="incrementer-quantity-unit"> {unit}</span> : null}
          {note ? (
            <>
              <br />
              <span className="incrementer-note">{note}</span>
            </>
          ) : null}
          {children ? (
            <>
              <br />
              <span className="incrementer-content">{children}</span>
            </>
          ) : null}
        </div>
        <IonButton
          disabled={isDefined(disabled) ? disabled : false}
          className="incrementer-incease"
          onClick={this.increase}
        >
          <IonIcon size={size} icon={add} />
        </IonButton>
        <IonAlert
          isOpen={enableLastItemAlert && showLastItemAlert}
          onDidDismiss={() => {
            this.setState({ showLastItemAlert: false });
          }}
          header={__('Are you sure you want to remove the selected item?')}
          message={__('')}
          buttons={[
            {
              text: __('No'),
              role: 'cancel',
              cssClass: 'secondary'
            },
            {
              text: __('Yes'),
              handler: () => {
                if (onDecrease) {
                  onDecrease(0);
                }
                if (onUpdate) {
                  onUpdate(0, data);
                }
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withTranslation(Incrementer);
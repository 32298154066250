import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IonButton, IonIcon, IonContent } from '@ionic/react';
import { arrowBack, arrowForward } from 'ionicons/icons';
import  MapWrapper from '../googleMapsReact';
import { getConfig } from '../../appConfig.js';
import { withTranslation } from '../../lib/translate';
import { isDefined, emptyString, sortLocations } from '../../lib/utils';
import Pullup from '../pullup';
import { Title, StrongText, NormalText,  Spacer } from '../common';
import Loading from '../spinner';
import { getRestaurants, setMyLocation } from '../../store/actions';
import { isMobile } from 'react-device-detect';
import Slider, { Slide } from '../slider';
import { ReactComponent as LocationIcon } from '../../assets/images/distance.svg';
import { ReactComponent as TimeIcon } from '../../assets/images/avg_pace.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/ad_units.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/mail.svg';
import { Geolocation } from '@capacitor/geolocation';
import './index.css';

const embedLocationInMapLayer = false;
const isMobileView = () => Capacitor.isNativePlatform() || isMobile;

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    const googleMapConfig = getConfig()?.services && getConfig().services?.google_maps ? getConfig().services?.google_maps : {};
    this.state = {
      stores: [],
      map: null,
      locationId: null,
      locationHolderNested: false,
      slideInit: false,
      initialLatCenter: null,
      initialLngCenter: null,
      initialZoom: googleMapConfig
        ? googleMapConfig?.defaultZoom
        : 18,
      initialPullupPosition: googleMapConfig?.initialPullupPosition && isMobileView() ? googleMapConfig.initialPullupPosition : 0,
      latitudeOffset: googleMapConfig?.latitudeOffset && isMobileView() ? parseFloat(googleMapConfig.latitudeOffset) : 0,
      longitudeOffset: googleMapConfig?.longitudeOffset && isMobileView() ? parseFloat(googleMapConfig.longitudeOffset) : 0,
      fromMarkerClick: false
    };
  }
  
  setStoresState = (myLocation = {}) => {
    let locations = []; 
    const soretedResturants = sortLocations(this.props.restaurants, myLocation);
    soretedResturants.forEach((restaurant) => {
      if (restaurant.is_published) {
        const position = this.parseLocation(restaurant.position);
        this.setState({
          initialLatCenter: position.lat,
          initialLngCenter: position.lng,
        });
        locations.push({
          latitude: position.lat,
          longitude: position.lng,
          ...restaurant,
        });
      }
    });
    if (locations.length > 0) {
      const locationId = locations[0].id;
      this.setState({ stores: locations, locationId });
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getRestaurants());
    this.position();
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      this.state.initialLatCenter !== prevState.initialLatCenter ||
      this.state.initialLngCenter !== prevState.initialLngCenter ||
      this.state.locationId !== prevState.locationId ||
      this.state.map !== prevState.map ||
      this.state.locationHolderNested !== prevState.locationHolderNested ||
      this.state.slideInit !== prevState.slideInit
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.restaurants.length !== this.props.restaurants.length) {
      this.setStoresState();
    }
    if (
      this.props.restaurants &&
      this.props.restaurants.length > 0 &&
      prevProps.restaurants &&
      prevProps.restaurants.length > 0 &&
      prevProps.restaurants[0].distance &&
      this.props.restaurants[0].distance &&
      prevProps.restaurants[0].distance !== this.props.restaurants[0].distance
    ) {
      this.setStoresState();
    }
  }

  changeMarker = (direction) => {
    const { stores, locationId, fromMarkerClick } = this.state;
    if (fromMarkerClick) {
      this.setState({ fromMarkerClick: false });
    } else {
      let nextIndex;
      const current = stores.find((s) => s.id === locationId);
      const curentIndex = stores.indexOf(current);
      if (direction === 'next') {
        if (curentIndex === stores.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = curentIndex + 1;
        }
      } else if (direction === 'prev') {
        if (curentIndex === 0) {
          nextIndex = stores.length - 1;
        } else {
          nextIndex = curentIndex - 1;
        }
      }
      const store = stores[nextIndex];
      this.setState({ locationId: store.id });
    }
  };

  parseLocation = (location = '') => {
    if (isDefined(location) && location !== '') {
      let position = location.split(',');
      return {
        lat: position[0],
        lng: position[1],
      };
    }

    return {};
  };

  translateWeekDays = (opening_times) => {
    const { __ } = this.props;

    opening_times = opening_times.replace('Monday', __('Monday'));
    opening_times = opening_times.replace('monday', __('monday'));
    opening_times = opening_times.replace('Tuesday', __('Tuesday'));
    opening_times = opening_times.replace('tuesday', __('tuesday'));
    opening_times = opening_times.replace('Wednesday', __('Wednesday'));
    opening_times = opening_times.replace('wednesday', __('wednesday'));
    opening_times = opening_times.replace('Thursday', __('Thursday'));
    opening_times = opening_times.replace('thursday', __('thursday'));
    opening_times = opening_times.replace('Friday', __('Friday'));
    opening_times = opening_times.replace('friday', __('friday'));
    opening_times = opening_times.replace('Saturday', __('Saturday'));
    opening_times = opening_times.replace('saturday', __('saturday'));
    opening_times = opening_times.replace('Sunday', __('Sunday'));
    opening_times = opening_times.replace('sunday', __('sunday'));

    return opening_times;
  };

  nestLocationLayer = () => {
    if (document) {
      const mapTG = document.querySelector('.gm-style');
      if (mapTG) {
        const locationHolder = document.querySelector('.map-location-pullup');
        mapTG.appendChild(locationHolder);
      }
    }
  };

  mapBoundsChanged = () => {
    if (!this.state.locationHolderNested) {
      this.setState({
        locationHolderNested: true,
      });
      this.nestLocationLayer();
    }
  };

  segmentChanged = (value) => {
    this.setState({ selectedTab: value });
    if (this.state.swiper) {
      this.state.swiper.slideTo(value);
    }
  };

  setLocation = (location) => {
    const { stores, swiper } = this.state;
    if (isDefined(location.id) && stores.length > 0) {
      const slideIndex = swiper.slides.findIndex((slide) => slide.getAttribute('storeid') === `${location.id}`);
      swiper.slideTo(slideIndex);
      this.setState({ locationId: location.id, fromMarkerClick: true });
    }
  };

  position = async () => {
		const myLocation = {
			latitude: null,
			longitude: null
		};
		try {
			const coordinates = await Geolocation.getCurrentPosition({
				enableHighAccuracy: false,
			});
			myLocation.latitude = coordinates.coords.latitude;
			myLocation.longitude = coordinates.coords.longitude;
		} catch (error) {
			console.log('Error getting location: ' + error.message);
		} 
		this.setStoresState(myLocation);
		this.props.dispatch(setMyLocation(myLocation));
	};

  render() {
    const { __ } = this.props;
    let { stores, locationId, initialZoom, initialPullupPosition } = this.state;
    let selectedRestaurant = null;
    let slideIndex = null;
    if (isDefined(locationId) && stores.length > 0) {
      selectedRestaurant = stores.find((s) => s.id === locationId);
      slideIndex = stores.indexOf(selectedRestaurant);
    }

    let isMonToFriSame = true;
    let ot = null;
    let ct = null;
    if (selectedRestaurant) {
      (selectedRestaurant.json_opening_time_info || []).forEach((item) => {
        if (['w6', 'w0'].indexOf(item.day_code) !== -1) {
          return;
        }
        if (!ot) {
          ot = item.open_time;
        }
        if (!ct) {
          ct = item.close_time;
        }
        isMonToFriSame = ot === item.open_time && ct === item.close_time && isMonToFriSame;
      });
    }
    let offsetBottom = 200;
    if (document && document.querySelector('.gm-style')) {
      offsetBottom = Math.round(document.querySelector('.gm-style').clientHeight * 0.4);
    }
    const typeControlPositionAttr = this.props.typeControlPosition
      ? { mapTypeControlOptions: { position: this.props.typeControlPosition } }
      : {};
    let height = '100%';
    let dynamicHeaderHeight = 50;
    if (
      document &&
      document.querySelector('.map-location-pullup') &&
      document.querySelector('.dynamic-header')
    ) {
      dynamicHeaderHeight = document.querySelector('.dynamic-header').clientHeight;
      height =
        document.querySelector('.map-location-pullup').offsetTop !== 0
          ? parseInt(document.querySelector('.map-location-pullup').offsetTop - dynamicHeaderHeight)
          : '100%';
    }
    return (
      <Loading additionalLoadingCondition={!isDefined(stores) || stores.length === 0}>
        <MapWrapper
          google={this.props.google}
          zoom={initialZoom}
          className="map"
          {...typeControlPositionAttr}
          streetViewControlOptions={{ position: 7 }}
          zoomControlOptions={{ position: 7 }}
          onBounds_changed={() => (embedLocationInMapLayer ? this.mapBoundsChanged : null)}
          style={{ height }}
          fullscreenControl={false}
          zoomControl={false}
          streetViewControl={false}
          mapTypeControl={false}
          mapTypeControlOptions={false}
          locations={stores}
          selectedLocation={selectedRestaurant}
          setLocation={(location) => this.setLocation(location)}
          latitudeOffset={this.state.latitudeOffset}
          longitudeOffset={this.state.longitudeOffset}
        >
        </MapWrapper>
        <Pullup
          className="map-location-pullup"
          top={35}
          offsetTop={70}
          offsetBottom={offsetBottom}
          contentOffsetBottom={20}
          initialPosition={initialPullupPosition}
        >
          <div className="map-location-holder">
            <div className="map-location-content">
              <div className="map-location-nav">
                <IonButton
                  className="location-arrow-btn"
                  size="small"
                  fill="clear"
                  onClick={() => this.state.swiper.slidePrev()}
                >
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              </div>
              <IonContent keyscrollEvents={true}>
                <Slider
                  className={(stores || []).length <= 1 ? 'swiper-no-swiping' : ''}
                  settings={{
                    loop: true,
                    initialSlide: slideIndex,
                    onSwiper: (swiper) => {
                      this.setState({ swiper })
                    },
                    onSlideNextTransitionEnd: () => {
                      this.changeMarker('next');
                    },
                    onSlidePrevTransitionEnd: () => {
                      this.changeMarker('prev');
                    },
                  }}
                >
                  {stores.map((store, i) => {
                    if (store.json_opening_time_info) {
                      store.json_opening_time_info = store.json_opening_time_info.map(
                        (time, index) => {
                          if (time.day_code) {
                            return { ...time };
                          }
                          return { ...time, day_code: `w${index}` };
                        },
                      );
                    }
                    return (
                      <Slide key={'slide-' + i} storeid={store.id} >
                        <div style={{marginBottom:'calc(2 * var(--ion-padding))'}}>
                          <Title className="strong-text">{store.name}</Title>
                        </div>
                        {store.address ? (
                          <div className='location-info-row'>
                            <LocationIcon/>
                            <div>
                              <StrongText className='block'>{__('Address')}</StrongText>
                              <NormalText >{store.address || emptyString}</NormalText>
                            </div>
                            
                          </div>
                        ) : null}
                        {store.json_opening_time_info ? (
                          <div className='location-info-row'>
                            <TimeIcon />
                            <div>
                              <StrongText className='block'>{__('Opening Hours')}</StrongText>
                              <div className="location-info">
                                {[
                                  'Monday',
                                  'Tuesday',
                                  'Wednesday',
                                  'Thursday',
                                  'Friday',
                                  'Saturday',
                                  'Sunday',
                                ].map((item, index) => {
                                  let temp = store.json_opening_time_info.filter(
                                    (t) => t.day.toLowerCase() === item.toLowerCase(),
                                  );
                                  return (
                                    <div key={index} className="location-info-segment">
                                      <NormalText>{this.translateWeekDays(item)}</NormalText>
                                      <div>
                                        {temp.length > 0 ? (
                                          <>
                                            {Array.isArray(temp[0].time) ? (
                                              <>
                                                {temp[0].time.map((el) => (
                                                  <StrongText className="block">{el}</StrongText>
                                                ))}
                                              </>
                                            ) : (
                                              <StrongText>{temp[0].time}</StrongText>
                                            )}
                                          </>
                                        ) : (
                                          <StrongText>{__('CLOSED')}</StrongText>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            </div>
                        ) : null}
                        {store.shop_info_json && store.shop_info_json.length > 0 ? (
                          <div className='location-info-row'>
                            <LocationIcon />
                            <div>
                              <StrongText className='block'>{__('Shop Info')}</StrongText>
                              {store.shop_info_json.map((info, i) => {
                                return (
                                  <div key={i}>
                                    <NormalText>
                                      <p>{info.key}:</p> {info.value}
                                    </NormalText>
                                    <br />
                                  </div>
                                );
                              })}
                            </div>
                            </div>
                        ) : null}
                        {store.telephone ? (
                          <div className='location-info-row'>
                            <PhoneIcon />
                            <div>
                              <StrongText className="no-margin block">{__('Telephone')}</StrongText>
                              <NormalText>{store.telephone || emptyString}</NormalText>
                            </div>
                            </div>
                        ) : null}
                        {store.email ? (
                          <div className='location-info-row'>
                            <EmailIcon />
                            <div>
                              <StrongText className='block'>{__('Email')}</StrongText>
                              <NormalText>{store.email || emptyString}</NormalText>
                            </div>
                            </div>
                        ) : null}
                      </Slide>
                    );
                  })}
                </Slider>
              </IonContent>
              <div className="map-location-nav">
                <IonButton
                  className="location-arrow-btn"
                  size="small"
                  fill="clear"
                  onClick={() => { this.state.swiper.slideNext() }}
                >
                  <IonIcon slot="icon-only" icon={arrowForward} />
                </IonButton>
              </div>
            </div>
          </div>
        </Pullup>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { restaurants } = state.restaurants;
  return {
    restaurants: restaurants || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(MapContainer)));
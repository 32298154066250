import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonCol, IonGrid, IonRow, IonCheckbox, IonButton, IonAlert } from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { NormalText, Spacer, StrongText, Subtitle } from '../../components/common';
import Loading from '../../components/spinner';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import { forwardTo, isEmptyArray, isEmptyObject } from '../../lib/utils';
import { selectTableItems } from '../../store/actions';
import CheckoutPay from '../checkout/CheckoutPay';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
import './index.css';
import BillPayAmount from '../../components/billPayAmount';


class SplitBillByItems extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			itemsPrice: 0,
			paidItems: [],
			updatedItems: [],
			selectedItems: [],
			isSetPayMethodModalOpen: false,
			tableData: {},
		};
	}
	setTablePayMethod = () => {
		Basket.setBillPayServiceCharge(0);
		Basket.setPortionToPay(0);
		this.props.dispatch({
			type: SET_TABLE_PAY_METHOD,
			data: {
				pay_method: 2,
				table_bill_id: Basket.bill_data.id,
			},
		});
	};
	handleInputChange = (e, item) => {
		const paidItems = Basket.paid_items;
		let updatedItems = [];
		if (e.target.checked) {
			updatedItems.push(...paidItems, item);
			// Basket.getTableItemPrice();
			this.props.dispatch(
				selectTableItems({
					tableNumber: Basket.getPayTableNumber(),
					businessLocationId: Basket.getRestaurant().pos_location_id,
					profileId: this.props.profile.id,

					selected_item: {
						user_id: this.props.profile.id,
						item_id: item.id,
						selected: true,
					},
				}),
			);
		} else {
			const removedItem = paidItems.filter((el) => el.id !== item.id);
			updatedItems = removedItem;
			this.props.dispatch(
				selectTableItems({
					tableNumber: Basket.getPayTableNumber(),
					businessLocationId: Basket.getRestaurant().pos_location_id,
					profileId: this.props.profile.id,
					selected_item: {
						user_id: this.props.profile.id,
						item_id: item.id,
						selected: false,
					},
				}),
			);
		}
		this.setState({ selectedItems: updatedItems }, () => {
			const { automaticServiceChargePercentage, automaticServiceChargeValue } = this.calculateServiceCharge(updatedItems);
			this.setState({ automaticServiceChargePercentage, automaticServiceChargeValue });
		});
	};
	onServiceChargeSetHandler = (serviceChargeValue) => {
		this.setState({ serviceChargeValue });
	};
	componentDidUpdate(prevProps) {
		if (
			this.props.tableData.total_due !== prevProps.tableData.total_due ||
			JSON.stringify(this.props.tableData.bill_data?.salesEntries) !== JSON.stringify(prevProps.tableData.bill_data?.salesEntries)
		) {
			this.setState({ tableData: this.props.tableData });
			Basket.setBillPayData(this.props.tableData);
			Basket.setTablePaymentAmount(this.props.tableData.total_due);
			const { automaticServiceChargeValue, automaticServiceChargePercentage } = this.calculateServiceCharge(Basket.paid_items);
			this.setState({ automaticServiceChargeValue, automaticServiceChargePercentage });
		}
	}
	componentDidMount() {
		if (isEmptyObject(Basket.bill_data)) {
			Basket.reset();
			forwardTo('/table-bill-pay');
			return;
		}
		if (isEmptyObject(Basket.getRestaurant()) || !Basket.getRestaurant()) {
			const selectedRestaurant = this.props.restaurants.find((restaurant) => restaurant.name == Basket.bill_data.restaurant_name);
			Basket.setRestaurant(selectedRestaurant);
		}
		Basket.calculateItemsPrice(this.props.profile.id);
		const { automaticServiceChargePercentage, automaticServiceChargeValue } = this.calculateServiceCharge(Basket.paid_items);
		this.setState({ automaticServiceChargePercentage, automaticServiceChargeValue });
	}
	calculateServiceCharge = (items) => {
		let total = 0;
		let totalPlusServiceCharge = 0;
		for (let i = 0; i < items.length; i++) {
			if (items[i].grossUnitAmount !== items[i].unitAmount) {
				total = total + parseFloat(items[i].grossUnitAmount);
				totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(items[i].unitAmount) + parseFloat(items[i].discountedAmount);
			}
			for (let j = 0; j < items[i].subLineItems.length; j++) {
				if (items[i].subLineItems[j].grossUnitAmount !== items[i].subLineItems[j].unitAmount) {
					total = total + parseFloat(items[i].subLineItems[j].grossUnitAmount);
					totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(items[i].subLineItems[j].unitAmount) + parseFloat(items[i].subLineItems[j].discountedAmount);
				}
			}
		}
		if (total > 0) {
			const automaticServiceChargeValue = totalPlusServiceCharge - total;
			const num = ((parseFloat(totalPlusServiceCharge) - parseFloat(total)) / parseFloat(total)) * 100;
			const automaticServiceChargePercentage = `${Math.floor(num * 10) / 10}%`;
			return { automaticServiceChargePercentage, automaticServiceChargeValue };
		} else {
			return {
				automaticServiceChargePercentage: 0,
				automaticServiceChargeValue: '0%',
			};
		}
	};
	itemHasPrice = (item) => {
		if (item.unitAmount > 0) {
			return true;
		} else {
			const sublinePrice = item.subLineItems.map((el) => parseInt(el.unitAmount)).reduce((partialSum, a) => partialSum + a, 0);
			return sublinePrice > 0;
		}
	};
	calculateItemPrice(item) {
		const sublinePrice = item.subLineItems.map((el) => parseFloat(el.grossUnitAmount)).reduce((partialSum, a) => partialSum + a, 0);
		return Basket.formatPrice(parseFloat(item.grossUnitAmount) + sublinePrice, true);
	}
	getAppliedDiscount(item) {
		const sublinePriceDiscount = item.subLineItems.map((el) => parseFloat(el.discountedAmount)).reduce((partialSum, a) => partialSum + a, 0);

		return parseFloat(item.discountedAmount) + sublinePriceDiscount;
	}
	backHandler = () => {
		Basket.reset();
		forwardTo('/table-bill-pay');
	};
	render() {
		const { __, clientSecret, profile, location, dispatch, tableData } = this.props;
		const { serviceChargeValue } = this.state;
		const deliveryOption = Basket.getDeliveryOption();
		const tableItems = Basket.getTableItems();
		const data = !isEmptyObject(tableData) ? tableData : Basket.bill_data;
		return (
			<Loading transparent>
				<Layout backHandler={this.backHandler} headerWithTitle title={`${__('Table')} ${Basket.getBillTableNumber()}`} color="transparent">
					<div className="absolute-content scrollable-y">
						<div className="bill-pay-title-wrapper">
							<NormalText className="block">
								{__('Table')} {Basket.getBillTableNumber()}
							</NormalText>
							<IonButton fill="clear" color="secondary" className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
								{__('Wrong table?')}
							</IonButton>
						</div>
						<Spacer size={1} />
						<BillPayAmount />
						<Spacer size={1} />
						<div className="table-items-to-pay-wrapper box-wrapper">
							{tableItems.map((item, index) => {
								const isChecked = !!data?.selected_items?.find((el) => el.item_id == item.id && el.user_id == this.props.profile.id);
								const isDisabled =
									!!Basket.bill_data?.paid_items?.find((el) => el.id == item.id) ||
									!!data?.selected_items?.find((el) => el.item_id == item.id && el.user_id !== this.props.profile.id);

								return (
									item.quantity > 0 && (
										<div key={index}>
											<IonItem lines="none" key={index} className="table-item-wrapper">
												{this.itemHasPrice(item) && (
													<IonCheckbox
														disabled={isDisabled}
														color="primary"
														slot="start"
														onIonChange={(event) => this.handleInputChange(event, item)}
														value={item.unitAmount * item.quantity}
														checked={isChecked}
													/>
												)}
												<IonGrid style={{ width: '100%' }}>
													<IonRow className={isDisabled ? 'disabled' : ''}>
														<IonCol size="8" className={parseFloat(item.grossUnitAmount - item.discountedAmount) > 0 ? '' : 'empty-item'}>
															<NormalText>{__(item.itemName)}</NormalText>
														</IonCol>
														<IonCol size="4" className="righted">
															<NormalText>{this.calculateItemPrice(item)}</NormalText>
														</IonCol>
													</IonRow>
													{this.getAppliedDiscount(item) > 0 && (
														<IonRow className="subitem pay-items" style={{ width: '100%', borderTop: 'none' }}>
															<IonCol size="8">
																<NormalText className="light-color">{`${__('Discount')} `}</NormalText>
															</IonCol>
															<IonCol size="4" className="righted">
																<NormalText className="light-color">-{Basket.formatPrice(this.getAppliedDiscount(item), true)}</NormalText>
															</IonCol>
														</IonRow>
													)}
													{!isEmptyArray(item.subLineItems)
														? item.subLineItems.map((subitem) => {
																return (
																	<IonRow className={isDisabled ? 'disabled subitem pay-items' : 'subitem pay-items'} key={subitem.id}>
																		<IonCol size="8">
																			<NormalText className="light-color">
																				{`${subitem.itemName} ${parseFloat(subitem.grossUnitAmount) > 0 && `(${Basket.formatPrice(subitem.grossUnitAmount)})`}`}
																			</NormalText>
																		</IonCol>
																	</IonRow>
																);
														  })
														: null}
												</IonGrid>
											</IonItem>
										</div>
									)
								);
							})}
							{this.state.automaticServiceChargeValue > 0 && (
								<div style={{ padding: '10px' }}>
									<IonRow>
										<IonCol size="8">{`${__('Service Charge')}`}</IonCol>
										<IonCol className="righted" size="4">
											{Basket.formatPrice(this.state.automaticServiceChargeValue)}
										</IonCol>
									</IonRow>
								</div>
							)}
							<div style={{ padding: '10px' }}>
								<IonRow>
									<IonCol>
										<StrongText className="bold ">{__('Total')}</StrongText>
									</IonCol>
									<IonCol className="righted">
										<StrongText className="bold ">{Basket.getBillTotal()}</StrongText>
									</IonCol>
								</IonRow>
							</div>
						</div>
						<Spacer size={1} />

						<TableBillServiceCharge serviceChargeSet={this.onServiceChargeSetHandler} />
						<Spacer size={1} />
						<>
							<IonGrid className="box-wrapper space-between pointer ">
								<IonRow>
									<IonCol>
										<Subtitle className="bold">{__('Payment amount')}</Subtitle>
									</IonCol>
									<IonCol className="righted">
										<Subtitle className="bold">{Basket.getTotalPlusServiceCharge()}</Subtitle>
									</IonCol>
								</IonRow>
							</IonGrid>
							<Spacer size={1} />
						</>

						{Basket.bill_pay_total > 0 && (
							<CheckoutPay clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} isBillPay={true} billTotal={Basket.bill_pay_total} />
						)}

						<Spacer size={1} />

						<IonButton disabled={Basket.bill_pay_total == 0} color="primary" expand="block" onClick={() => forwardTo('/checkout', { fromTablePay: true })}>
							{__('Pay with card')}
						</IonButton>
						<IonButton expand="block" fill="clear" color="primary" className="link underlined" onClick={() => this.setState({ isSetPayMethodModalOpen: true })}>
							{__('Or pay custom amount')}
						</IonButton>
					</div>
					<IonAlert
						isOpen={this.state.isSetPayMethodModalOpen}
						onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
						header={__('Are you sure that you want to set this as your chosen payment method?')}
						// message={__(this.getRestaurantDataMessage(__))}
						buttons={[
							{
								text: __('Cancel'),
								role: 'cancel',
								cssClass: 'secondary',
								handler: () => this.setState({ isSetPayMethodModalOpen: false }),
							},
							{
								text: __('Confirm'),
								role: 'submit',
								cssClass: 'secondary',
								handler: () => this.setTablePayMethod(),
							},
						]}
					/>
				</Layout>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	const { tableData, restaurants } = store.restaurants;
	const { myLocation } = store.common;
	const { auth, profile } = store.profile;
	const { clientSecret } = store.orders;

	return {
		tableData,
		restaurants,
		myLocation,
		auth,
		profile,
		clientSecret,
	};
};

export default connect(mapStateToProps)(withTranslation(SplitBillByItems));

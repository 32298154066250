import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonItem, IonLabel, IonList, IonRadioGroup, IonRadio, IonButtons, IonAlert, IonIcon } from '@ionic/react';
import Layout from '../../components/layout';
import { Title, NormalText, SmallText, Spacer, StrongText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import { forwardTo, checkForDeliveryOption, isEmptyObject, isWebConfig, isTimeAvaibleInMenu, isTimeInRange } from '../../lib/utils';
import {
	setDeliveryAddress,
	postCodeCheck,
	setPostCodeData,
	getNearestLocation,
	setCommonModal,
	removeDeliveryAddress,
	setOrdersProp,
	getRestaurants,
	showToast,
	setDeliveryOption,
} from '../../store/actions';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';

import './index.css';
import { trashBinOutline } from 'ionicons/icons';
import { getConfig } from '../../appConfig';

class ScheduledDelivery extends React.Component {
	state = {
		selectedAddress: null,
		deliveryZoneOption: '',
		deliveryZone: [],
		restaurant: {},
		removeDeliveryAddress: null,
		selectedRestaurantPage: false,
	};
	scheduledDeliveryOnMultiVenueDisabled = (store) => {
		if (Basket.has_multi_basket && this.props.location?.state?.saveOrderData) {
			const collectionTime = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('DD/MM/YYYY');
			const daysAhead = store?.active_menus?.scheduled_delivery?.days_ahead + 1 || 8;
			const timezone = getConfig().timezone;
			const unavailableDates = store?.active_menus?.scheduled_delivery.unavailable_dates.map((slot) => moment(slot, 'DD/MM/YYYY').format('DD/MM/YYYY')) ?? [];
			const futureDays = Array(daysAhead)
				.fill('')
				.map((day, i) => moment().tz(timezone).add(i, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('DD/MM/YYYY'))
				.filter((day) => !unavailableDates.includes(day));
			if (!futureDays.includes(collectionTime)) {
				return true;
			} else {
				const collectionDate = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('d');
				let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
				const dayName = days[collectionDate];
				const slots = store?.active_menus?.scheduled_delivery.slots[dayName];
				const collectionHour = moment.unix(Basket.collection_time).tz(getConfig().timezone).format('HH:mm');
				if (!slots) {
					return true;
				}
				return !isTimeInRange(collectionHour, slots.start_time, slots.end_time);
			}
		} else {
			return false;
		}
	};
	componentDidMount() {
		if (this.props?.location?.state?.selectedRestaurant) {
			const selectedRestaurant = this.props.restaurants.find((restaurant) => restaurant.id === this.props?.location?.state?.selectedRestaurant.restaurant_id);
			const orderType = getConfig().delivery.filter((el) => el.id === 'scheduled-delivery');
			const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
			if (!(selectedRestaurant.can_scheduled_delivery_order && selectedRestaurant.is_published) || orderTypeDisabled) {
				forwardTo('/history', { tab: 'order' });

				this.props.dispatch(showToast(this.props.__('This restaurant is currently not accepting orders'), 'warning'));
				Basket.reset();
			}
		}
		if (Basket.getDeliveryOption().id === 'gift-vouchers') {
			Basket.reset();
		}
		Basket.setOrderType('scheduled-delivery');
		let option = (getConfig().delivery || []).find((d) => d.id === 'scheduled-delivery');
		Basket.setDeliveryOption(option);
		checkForDeliveryOption(Basket.getDeliveryOption(), '/scheduled-delivery').then((deliveryOption) => {
			if (deliveryOption) {
				this.props.dispatch(setDeliveryOption(deliveryOption));
			}
			this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
			this.props.dispatch(getRestaurants());

			const { address_list } = this.props.profile;
			if (address_list && address_list.length > 0) {
				const defaultAddress = address_list.find((da) => da.default);
				this.setState({ selectedAddress: defaultAddress || address_list[0] });
			} else {
				forwardTo('/delivery-address-check', { scheduled: true });
			}
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.deliveryZoneOption !== this.state.deliveryZoneOption) {
			Basket.setDeliveryMinOrder(this.state.deliveryZone[this.state.deliveryZoneOption].delivery_zone.min_order);
		}
		if (this.props.profile.address_list?.length === 0) {
			forwardTo('/delivery-address-check', { scheduled: true });
		}
		const { restaurants } = this.props;
		checkForDeliveryOption(Basket.getDeliveryOption(), '/scheduled-delivery').then(() => {
			if (this.props.checkedCodeData.length > 0 && prevProps.checkedCodeData !== this.props.checkedCodeData) {
				const copiedRestaurants = JSON.parse(JSON.stringify(restaurants));
				const filteredRestaurants = copiedRestaurants.filter((restaurant) => !restaurant.can_scheduled_delivery_order);
				let deliveryZone = JSON.parse(JSON.stringify(this.props.checkedCodeData)).filter((el) => filteredRestaurants.every((fd) => fd.id !== el.restaurant_id));
				this.setState({ selectedRestaurantPage: true, deliveryZone });
			} else if (prevState.checking && this.props.checkedCodeData.length === 0) {
				this.setState({ checking: false, postalCodeValid: false });
			}
		});
	}
	confirmAddress = () => {
		const { selectedAddress } = this.state;
		const restaurants = this.props.restaurants;

		const selectedRestaurantId = this.state.deliveryZone[this.state.deliveryZoneOption].restaurant_id;
		if (selectedAddress) {
			this.props.dispatch(setDeliveryAddress(selectedAddress));
			this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
			Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === selectedRestaurantId));
			if (!this.props.location?.state?.saveOrderData) {
				if (getConfig().general.hasServiceCharge && getConfig().general.service_charge_modules?.includes('scheduled-delivery')) {
					if (getConfig().flags.applyAutomaticServiceCharge) {
						if (Basket.getRestaurantServiceCharge()) {
							const service_charge_original = Basket.getRestaurantServiceCharge();
							Basket.setServicePercentage(parseFloat(service_charge_original[1] * 100));
						} else {
							const defaultServiceCharge =
								typeof getConfig().general.defaultServiceCharge == 'string' ? JSON.stringify(getConfig().general.defaultServiceCharge) : getConfig().general.defaultServiceCharge;
							Basket.setServicePercentage(parseFloat(defaultServiceCharge[1] * 100));
						}
					} else {
						Basket.setServicePercentage(0);
					}
				} else {
					Basket.setServicePercentage(0);
				}
			}

			const deliveryCharge = this.state.deliveryZone[this.state.deliveryZoneOption].delivery_zone.price;
			Basket.setDeliveryPrice(deliveryCharge);
			Basket.setDeliveryAddress(selectedAddress);
			if (this.props.location?.state?.saveOrderData && Basket.has_multi_basket) {
				forwardTo('/order');
			} else {
				forwardTo('/delivery-address-add', {
					selectedRestaurant: this.props.location?.state?.selectedRestaurant,
					scheduled: true,
				});
			}
		}
	};

	check = (value, type) => {
		Basket.setDeliveryAddress(this.state.selectedAddress);

		if (type === 'postcode') {
			this.props.dispatch(postCodeCheck(value, false));
			this.setState({ initial: false, checking: true });
		} else if (type === 'distance' || type === 'polygon') {
			this.props.dispatch(getNearestLocation(value, false, true));
			this.setState({ initial: false, checking: true, restaurant: {} });
		} else {
			this.setState({ initial: true }, () => {
				this.props.dispatch(setPostCodeData({ data: [] }));
			});
		}
		this.setState({ initial: false, checking: true });
	};

	changeDeliveryZone = (event) => this.setState({ deliveryZoneOption: event.detail.value, error: '' });

	checkAvailableSlotsForToday = (restaurant) => {
		let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
		let today = days[moment().format('d')];
		const day = {
			Monday: 'w1',
			Tuesday: 'w2',
			Wednesday: 'w3',
			Thursday: 'w4',
			Friday: 'w5',
			Saturday: 'w6',
			Sunday: 'w0',
		};
		let selectedRestaurant = this.props.restaurants.find((r) => r.id === restaurant.restaurant_id);
		let deliveryTimes = selectedRestaurant.charter_delivery_times_json;
		let flag = false;
		if (!isEmptyObject(deliveryTimes)) {
			for (let i = 0; i < deliveryTimes.length; i++) {
				deliveryTimes[i].availability.forEach((dt) => {
					if (dt.d === day[today]) {
						let addedTime = null;
						addedTime = moment().add(restaurant.charter_delivery_order_slot_lead_time, 'minutes');
						if (addedTime.isBefore(moment(dt.end, 'hh:mm'))) {
							flag = true;
						}
					}
				});
			}
		}

		return flag;
	};

	handleRemoveDeliveryAddress = (data, flag) => {
		this.setState({ removeDeliveryAddress: data });
		this.props.dispatch(setOrdersProp('removeAddressModal', flag));
	};

	removeDeliveryAddress = () => {
		this.props.dispatch(removeDeliveryAddress(this.state.removeDeliveryAddress));
		this.setState({ removeDeliveryAddress: null });
	};

	render() {
		const { __, profile, deliveryRangeType, restaurants } = this.props;
		const { selectedAddress, deliveryZoneOption, deliveryZone } = this.state;
		const { address_list } = profile;
		const hasAddress = address_list && address_list.length > 0;
		const hideDeliveryFee = getConfig().flags?.hide_delivery_fee;
		const hideMinimumSpend = getConfig().flags?.hide_minimum_spend;
		return (
			<Loading transparent>
				<Layout showLoginIcon={getConfig().flags.hasGuestCheckout && Basket.itemsCount() == 0} showHamburger headerWithTitle={true} title={__('Delivery order')} color="transparent">
					<div className="flex-row-wrapper absolute-content">
						{!this.state.selectedRestaurantPage ? (
							<>
								<div className="scrollable-y">
									<Title className="web-only">{__('Delivery order')}</Title>
									{hasAddress ? (
										<>
											<NormalText>{__('Select your delivery address')}</NormalText>
											<Spacer />
											<IonList lines="none" className="box-wrapper">
												<IonRadioGroup
													value={selectedAddress}
													onIonChange={(e) =>
														this.setState({
															selectedAddress: e.detail.value,
														})
													}
												>
													{address_list.map((da, index) => {
														return (
															<IonItem key={'delivery-address-' + index} lines="none">
																<div tabIndex="-1"></div>
																<IonRadio color={isWebConfig() ? 'primary' : 'white'} slot="start" value={da} />
																<IonButtons slot="end">
																	<IonButton onClick={() => this.handleRemoveDeliveryAddress(index, true)} className="link" color="primary">
																		<IonIcon color="primary" icon={trashBinOutline} />
																	</IonButton>
																</IonButtons>
																<IonLabel>
																	<NormalText>
																		{da.addressLine1}, {da.place}
																	</NormalText>
																	<br />
																	<NormalText >{da.postalCode}</NormalText>
																</IonLabel>
															</IonItem>
														);
													})}
												</IonRadioGroup>
											</IonList>
										</>
									) : null}
									<IonButton
										fill="clear"
										className="link underlined"
										color='secondary'
										onClick={() => {
											forwardTo('/delivery-address-check', { scheduled: true });
										}}
									>
										{__((hasAddress ? 'Or add another' : 'Add') + ' delivery address')}
									</IonButton>
								</div>
								{getConfig().frenchDisclaimer?.disclaimerImage && (
									<div className="disclaimer-wrapper">
										<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
									</div>
								)}
								<div className="flex-min" style={{ paddingTop: 'var(--ion-padding)' }}>
									<IonButton
										disabled={!selectedAddress}
										expand="block"
										color="primary"
										onClick={() =>
											this.check(
												selectedAddress ? [selectedAddress.addressLine1, selectedAddress.addressLine2, selectedAddress.place, selectedAddress.postalCode].join(', ') : '',
												deliveryRangeType,
											)
										}
									>
										{__('Continue')}
									</IonButton>
								</div>
							</>
						) : (
							<div>
								<Title className="web-only">{__('Delivery order')}</Title>
								<NormalText>{__('Select a restaurant')}</NormalText>
								<Spacer />
								<IonList className="box-wrapper">
									<IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
										{deliveryZone
											.sort((a, b) => {
												return a.delivery_zone.price - b.delivery_zone.price;
											})
											.map((restaurant, i) => {
												const { restaurant_name, restaurant_address, delivery_zone } = restaurant;

												const price = restaurant.delivery_zone.price;
												let selectedRestaurant = restaurants.find((r) => r.id === restaurant.restaurant_id);
												return (
													<IonItem className='restaurant-wrapper' key={i} lines="none" disabled={this.scheduledDeliveryOnMultiVenueDisabled(selectedRestaurant)}>
														<div tabIndex="-1"></div>
														<IonRadio color={isWebConfig() ? 'primary' : 'white'} slot="start" value={i} />
														<IonLabel className="ion-text-wrap" color="dark">
															<StrongText className="primary-color">{restaurant_name}</StrongText>
															<SmallText className="block">{restaurant_address}</SmallText>
															<div className="delivery-additional-restaurant-information">
																<div>
																	{!Basket.getDeliveryOption()?.hide_delivery_fee && (
																		<>
																			<SmallText className="block">
																				{price > 0
																					? restaurant.delivery_charge_in_percent
																						? `${__('Delivery price')} -  ${price} %`
																						: `${__('Delivery price')} - ${Basket.getCurrency().label} ${price}`
																					: `${__('Free Delivery')}`}
																			</SmallText>
																		</>
																	)}
																	{!Basket.getDeliveryOption()?.hide_minimum_spend && (
																		<>
																			<SmallText className="block">
																				{__('Minimun Order')}:{Basket.formatPrice(delivery_zone.min_order)}
																			</SmallText>
																		</>
																	)}
																</div>
															</div>
														</IonLabel>
													</IonItem>
												);
											})}
									</IonRadioGroup>
								</IonList>
								<Spacer size={1} />
								<IonButton
									disabled={deliveryZoneOption === ''}
									expand="block"
									color="primary"
									onClick={() => {
										this.confirmAddress();
									}}
								>
									{__('Continue')}
								</IonButton>
							</div>
						)}
					</div>
				</Layout>
				<IonAlert
					isOpen={this.props.removeAddressModal === true}
					onDidDismiss={() => this.handleRemoveDeliveryAddress(null, false)}
					header={__('Confirm')}
					message={__('Do you you want to remove this delivery address?')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: __('Remove'),
							handler: () => this.removeDeliveryAddress(),
						},
					]}
				/>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { profile } = state.profile;
	const { deliveryOption, checkedCodeData, removeAddressModal } = state.orders;
	const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common;
	const { restaurants } = state.restaurants;
	return {
		profile,
		deliveryOption,
		deliveryRangeType,
		isChooseDeliveryModalOpen,
		checkedCodeData,
		restaurants,
		removeAddressModal,
	};
};

export default connect(stateToProps)(withTranslation(ScheduledDelivery));

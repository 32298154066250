import moment from './moment';
import { isDefined, getLocale, forwardTo, isEmptyObject, checkIdenticalArrays } from './utils';
import { getConfig } from '../appConfig';
import asyncStorage from './asyncStorage';
import { store } from '../index';
import { SET_ORDERS_PROP, CREATE_ORDER, CREATE_STRIPE_ORDER, CREATE_YOCO_ORDER, SET_COMMON_PROP, SEND_TABLE_BILL, SET_COMMON_MODAL } from '../store/constants';
import * as actions from '../store/actions';
import BigNumber from './bignumber';
import { getUserLang, translate } from './translate';
import { createDiscount, createDiscountPackage } from './subscriptionFactory';
import 'moment-timezone';

const { getIkentooMenu, showToast } = actions;
const zero = 0;
const negativeOne = -1;
const positiveOne = 1;
const emptyStr = '';
const errorMessages = {
	requiredOneItem: 'Must have at least one selected option',
	oneOrMode: 'Must have selected zero or one option',
	basketNotValid: 'Basket has been cleared because collection time is passed. Please start new order',
	maxItems: 'You have selected maximum number of options',
	minItems: 'You must select required minimum of options. Min:',
};
const pointRatio = 100; //100 points = 1 gbp/eur
const enableCollectionTimeValidation = true;
const delivery = isDefined(getConfig().delivery) ? getConfig().delivery : [];
const _vouchersType = getConfig().vouchersType;
const vouchersType = isDefined(_vouchersType) ? _vouchersType : 1;
class Basket {
	constructor() {
		this.create();
	}
	setLang(profile) {
		this.lang = getUserLang(profile);
	}

	translate(message) {
		if (!this.lang) {
			return message;
		}
		return translate(message, this.lang);
	}
	create = (is_multi_basket) => {
		this.items = [];
		this.restaurant = null;
		this.menu = null;
		this.collection_time = null;
		this._collection_time = null;
		this.discount_total = zero;
		this.applied_vouchers = [];
		this.applicable_vouchers = [];
		this.applied_gift_cards = [];
		this.total = zero;
		this.subTotal = zero;
		this.selectedCard = null;
		this.pointsApplied = zero;
		this.pointsAppliedValue = zero;
		this.order_type = '';
		this.mobile = null;
		this.mobile_code = null;
		this.mobile_value = null;
		this.delivery_option = {};
		this.delivery_address = null;
		this.pick_up_point = null;
		this.pay_on_collection = false;
		this.delivery_price = 0;
		this.delivery_min_order = 0;
		this.min_order = getConfig().general.mininumOrderAmount;
		this.allergen_data = [];
		this.cutoffTime = moment().unix();
		this.table_name = null;
		this.passed_order = null;
		this.process_fee_value = 0;
		this.service_charge_value = null;
		this.service_charge = 0;
		this.is_asap = false;
		this.next_collection_time = [];
		this.is_gift = false;
		this.processing_fee_description = 0;
		this.menu_id = null;
		this.already_paid = 0;
		this.portion_to_pay = 0;
		this.bill_total = 0;
		this.billSubTotal = 0;
		this.amount_left_to_pay = 0;
		this.table_pay_method = 0;
		this.table_items = [];
		this.bill_data = {};
		this.paid_amount = 0;
		this.table_members = 0;
		this.table_members_paid = 0;
		this.table_payment_amount = 0;
		this.table_members_to_pay = 0;
		this.paid_items = [];
		this.table_items_price = 0;
		this.selected_items = [];
		this.bill_pay_service_charge = 0;
		this.bill_pay_service_charge_applied = 0;
		this.bill_pay_total = 0;
		this.restaurant_delivery_price = 0;
		this.custom_field = {};
		this.uuid = null;
		this.gift_voucher_data = {}
		this.has_multi_basket = is_multi_basket;
		this.multi_basket_items = [];
		this.additional_data = {};
		this.tax_breakdown = [];
		this.total_tax = 0;
		this.validation_id = null;
		this.channel_link_id = null;
		this.channel_name = null;
		this.send_to_deliverect = false;
		this.process_fee_amount = 0;
	};

	setNamedTimeSlotsData = (named_time_slots_data) => {
		this.additional_data = { ...this.additional_data, named_time_slots_data };
	};

	setTaxBreakdown = (tax_breakdown) => {
		this.tax_breakdown = tax_breakdown;
		this.total_tax = tax_breakdown.reduce((acc, obj) => acc + obj.total_tax, 0) * 100;
		this.instanceStateChanged();
	};

	getAdditionalTaxData = () => {
		if (getConfig().general.globalFeesBusinessLocationId) {
			const deliveryPrice = parseFloat(this.delivery_price) || 0;
			const processFee = this.process_fee_value || 0;
			const serviceCharge = this.service_charge || 0;
			const addtionalData = {
				restaurant: {
					business_location_id: `global_${getConfig().general.globalFeesBusinessLocationId}`,
				},
				is_global: true,
				items: [],
			};
			addtionalData.items.push({
				item: {
					'@type': 'delivery_fee',
					productPrice: this.round(deliveryPrice, 2).toFixed(2),
					type: 'delivery_fee',
				},
				quantity: 1,
			});
			addtionalData.items.push({
				item: {
					'@type': 'processing_fee',
					productPrice: this.round(processFee, 2).toFixed(2),
					type: 'processing_fee',
				},
				quantity: 1,
			});
			addtionalData.items.push({
				item: {
					'@type': 'service_charge',
					productPrice: this.round(serviceCharge, 2).toFixed(2),
					type: 'service_charge',
				},
				quantity: 1,
			});
			return addtionalData;
		} else {
			return false;
		}
	};
	groupTaxData = (data) => {
		return Object.values(
			data.flat().reduce((acc, { tax_name, tax_amount }) => {
				if (!acc[tax_name]) {
					acc[tax_name] = { tax_name, tax_amount: 0 };
				}
				acc[tax_name].tax_amount += tax_amount;
				return acc;
			}, {}),
		);
	};

	getTaxDataForDisplay = () => {
		const taxLines = this.groupTaxData(this.tax_breakdown.map((item) => item.tax_lines));
		return taxLines;
	};
	getTaxBreakdown = () => {
		return this.tax_breakdown;
	};
	getTotalTaxBreakdown = () => {
		return this.tax_breakdown.reduce((acc, obj) => acc + obj.total_tax, 0);
	};
	setMultiBasket = () => {
		this.has_multi_basket = true;
		this.instanceStateChanged();
	};
	reset = () => {
		this.create(store.getState().common?.clientProfile?.is_multi_basket);
		store.dispatch({ type: 'CLEAR_IKENOO_MENU' });
		store.dispatch({ type: SET_COMMON_PROP, key: 'taxCalculated', value: false });
		this.instanceStateChanged();
	};

	// SETTERS ----------------------------------------------------------------------------------------
	// IMPORTANT: all changes of any instance property must triger SAVING new instance state to the local storage (instanceStateChanged)
	setRestaurant = (restaurant = null) => {
		this.restaurant = restaurant;
		this.instanceStateChanged();
	};

	setMenu = (menu = null) => {
		this.menu = menu;

		this.instanceStateChanged();
	};

	setMobile = (mobile = null) => {
		this.mobile = mobile;

		this.instanceStateChanged();
	};
	setMobileCode = (mobile_code = null) => {
		this.mobile_code = mobile_code;

		this.instanceStateChanged();
	};
	setMobileValue = (mobile_value = null) => {
		this.mobile_value = mobile_value;
		this.mobile = `${this.mobile_code}${mobile_value}`
		this.instanceStateChanged();
	};
	setSelectedCard = (selectedCard) => {
		this.selectedCard = selectedCard;

		this.instanceStateChanged();
	};

	setCollectionTime = (collection_time = null) => {
		if (collection_time && collection_time !== 'asap') {
			this._collection_time = collection_time;
			this.collection_time = moment(collection_time).tz(getConfig().timezone).unix();
			this.instanceStateChanged();
			// only for apps which dont have delivery module (only C&C)
			if (!isDefined(delivery)) {
				this.setOrderType('collection');
			}
		} else {
			this._collection_time = 'asap';
			this.collection_time = 'asap';
			this.instanceStateChanged();
		}
	};

	setNextCollectionTime = (next_collection_time = []) => {
		this.next_collection_time = next_collection_time;
	};

	setCutoffTime = (cutoffTime = null) => {
		if (cutoffTime) {
			this.cutoffTime = cutoffTime;
			this.instanceStateChanged();
		}
	};

	setUUID = (uuid, overwrite) => {
		if (!this.uuid || overwrite) {
			this.uuid = uuid;
		}
		this.instanceStateChanged();
	};
	getUUID = () => {
		return this.uuid;
	};
	setDeliverectDate = (data) => {
		this.validation_id = data.validation_id;
		this.send_to_deliverect = true;
		this.channel_link_id = data.channel_link_id;
		this.channel_name = data.channel_name;
		this.instanceStateChanged();
	};
	setASAP = (is_asap = true) => {
		this.is_asap = is_asap;
		this.instanceStateChanged();
	};

	addVoucher = (voucher, applicableVoucher) => {
		if (voucher) {
			this.applied_vouchers = [];
			this.applicable_vouchers = [];
			if (vouchersType === 3) {
				if (applicableVoucher && applicableVoucher.type) {
					let voucherRes = { ...voucher, type: applicableVoucher.type };
					this.applied_vouchers.push(voucherRes);
				}
			} else {
				this.applied_vouchers.push(voucher);
			}
			this.applicable_vouchers.push(applicableVoucher);

			this.instanceStateChanged();
			// this.clearAllDiscounts()
		}
	};
	setSubscription = (subscription) => {
		this.subscription = subscription;
		if (subscription) {
			this.discount = createDiscount(subscription);
			this.discountPackage = createDiscountPackage(subscription);
		} else {
			this.discount = null;
			this.discountPackage = null;
		}
		this.instanceStateChanged();
	};


	// available types: delivery, collect, table, pick-up-point
	setOrderType = (orderType = 'collection') => {
		if (['delivery', 'collection', 'table', 'pick-up-point', 'charter-delivery', 'scheduled-collection', 'scheduled-delivery', 'bill-pay', 'pick-up-at-counter', 'browse-menu'].indexOf(orderType) === -1) {
			this.toastMessage(this.translate('Wrong order type'));
		}

		const option = this.delivery_option;
		this.order_type = orderType;
		this.delivery_option = option;

		// add service charge

		this.instanceStateChanged();
	};

	setTableNumber = (tableNumber) => {
		this.table_name = tableNumber;
		this.instanceStateChanged();
	};

	setDeliveryOption = (delivery_option) => {
		this.delivery_option = delivery_option;
	};

	setDeliveryAddress = (deliveryAddress) => {
		this.delivery_address = deliveryAddress;
		this.pick_up_point = null;
		this.instanceStateChanged();
	};

	setPickUpPoint = (pickUpPoint) => {
		this.pick_up_point = pickUpPoint;
		this.delivery_address = null;
		this.instanceStateChanged();
	};
	setDeliveryPrice = (deliveryPrice) => {
		this.restaurant_delivery_price = deliveryPrice;
		this.delivery_price = deliveryPrice;
		this.instanceStateChanged();
	};
	setProcessingFee = (value) => {
		this.process_fee_value = value;
	};
	calculateProcessingFee = (isGift) => {
		let subTotal;
		if (this.process_fee_amount) {
			this.setProcessingFee(this.process_fee_amount / 100);
			return this.process_fee_amount / 100;
		}
		const addProcessingFee = isGift ? getConfig().flags.hasProcessingFeeOnVouchers : true;
		if (!this.service_charge_value) {
			subTotal = new BigNumber(this.subTotal).minus(this.service_charge).toNumber();
		} else {
			subTotal = new BigNumber(this.subTotal).minus(this.service_charge_value).toNumber();
		}
		let process_fee_value = this.process_fee_value || 0;

		const processFeeConfig = getConfig().processFeeConfig;
		if (processFeeConfig || processFeeConfig?.length) {
			const appliedProcessFee = processFeeConfig.filter((el) => {
				return el.min < subTotal && el.max >= subTotal;
			});
			if (appliedProcessFee) {
				if (!appliedProcessFee[0]?.isFixedAmount) {
					let processingFeePecentage = this.round((subTotal * appliedProcessFee[0]?.value) / 100, 2);
					if (processingFeePecentage) {
						process_fee_value = processingFeePecentage;
						this.processing_fee_description = appliedProcessFee[0]?.value;
					} else if (processingFeePecentage === 0) {
						process_fee_value = 0;
						this.processing_fee_description = appliedProcessFee[0]?.value;
					}
				} else {
					let processingFee = appliedProcessFee[0]?.value;
					if (processingFee) {
						process_fee_value = processingFee;
						this.processing_fee_description = 0;
					} else if (processingFee === 0) {
						process_fee_value = 0;
						this.processing_fee_description = 0;
					}
				}
			}

			this.setProcessingFee(addProcessingFee ? process_fee_value : 0);
		}

		return addProcessingFee ? process_fee_value : 0;
	};
	setDeliveryMinOrder = (minOrder) => {
		this.delivery_min_order = parseFloat(minOrder).toFixed(2);
		this.instanceStateChanged();
	};
	getDeliveryMinOrder = () => this.delivery_min_order;

	setMinOrder = (minOrder) => {
		this.min_order = parseFloat(minOrder).toFixed(2);
		this.instanceStateChanged();
	};

	setAllergen = (allergen) => {
		if (allergen.length > 0) {
			this.allergen_data.push(allergen);
			this.instanceStateChanged();
		}
	};

	setPassedOrder = (passed_order = null) => {
		this.passed_order = passed_order;
		this.instanceStateChanged();
	};

	getPassedOrder = () => this.passed_order;

	setServicePercentage = (service_percentage = 0) => {
		this.service_percentage = service_percentage;
		this.instanceStateChanged();
		this.clearAllDiscounts();
		if (getConfig().flags.hasTaxBreakdown && this.tax_breakdown.length > 0) {
			this.setTaxBreakdown([]);
			store.dispatch({ type: SET_COMMON_PROP, key: 'taxCalculated', value: false });
		}
	};
	getAllergen = () => this.allergen_data;

	getDeliveryAddress = () => this.delivery_address;

	getPickUpPoint = () => this.pick_up_point;

	getDeliveryPrice = () => this.formatPrice(this.delivery_price);

	_getDeliveryPrice = () => this.delivery_price;

	getProcessingFee = () => {
		return this.formatPrice(this.process_fee_value);
	};
	getDeliveryOption = () => this.delivery_option;

	getMinOrder = () => this.min_order;

	getTableNumber = () => this.table_name;

	getASAP = () => this.is_asap;

	getServicePercentage = () => this.service_percentage;

	_getServicePercentage = () => this.getServicePercentage() + '%';

	getServiceChargeValue = () => this.service_charge_value;

	getFormatedServiceChargeValue = () => this.formatPrice(this.service_charge_value, true);

	calculateItemsTotal = ()=>{
		let orderValue = BigNumber(zero);

		if (this.has_multi_basket) {
			orderValue = orderValue.plus(
				this.multi_basket_items.reduce((acc, obj) => {
					const itemPrices = obj.items.reduce((itemAcc, itemObj) => itemAcc + this.calculateItemPrice(itemObj, true), 0);
					return acc + itemPrices;
				}, 0),
			);
		} else {
			this.items.forEach((basketItem) => {
				orderValue = orderValue.plus(this.calculateItemPrice(basketItem));
			});
		}
		return this.formatPrice(orderValue.toNumber(),true)
	}
	calculateServiceCost = () => {
		if (this.service_percentage === false) {
			return this.service_charge;
		} else {
			const _service_percentage = new BigNumber(this.service_percentage);
			let _order_value = BigNumber(zero);
			if (this.has_multi_basket) {
				_order_value = _order_value.plus(
					this.multi_basket_items.reduce((acc, obj) => {
						const itemPrices = obj.items.reduce((itemAcc, itemObj) => itemAcc + this.calculateItemPrice(itemObj, true), 0);
						return acc + itemPrices;
					}, 0),
				);
			} else {
				this.items.forEach((basketItem) => {
					_order_value = _order_value.plus(this.calculateItemPrice(basketItem));
				});
			}
			const _service_charge = isNaN(_order_value.div(100).times(_service_percentage).toNumber()) ? zero : _order_value.div(100).times(_service_percentage).toNumber();

			this.service_charge = this.round(_service_charge, 2);
			return this.round(_service_charge, 2);
		}
	};
	setServiceCharge = (num) => {
		this.service_charge = this.round(parseFloat(num), 2);
		this.service_percentage = false;
		this.instanceStateChanged();
	};
	_calculateServiceCost = (inlucdeZero) => this.formatPrice(this.calculateServiceCost(), inlucdeZero);

	getServiceCharge = (inlucdeZero) => this.formatPrice(this.service_charge, inlucdeZero);

	_getServiceCharge = () => this.service_charge;

	getNextCollectionTime = () => this.next_collection_time;

	selectedChoicesSkus = (choices) => {
		let skus = [];
		if (choices && choices.length > 0) {
			choices.forEach((choice) => {
				if (choice && choice.length > 0) {
					skus.push(...this.selectedChoicesSkus(choice));
				} else {
					if (choice.sku) {
						skus.push(choice.sku);
					}
					return;
				}
			});
		}

		return skus;
	};
	getCustomFieldValue = () => {
		return this.custom_field;
	};

	setCustomFieldValue = (val) => {
		this.custom_field = val;
	};
	getSelectedCard = () => {
		return this.selectedCard;
	}
	addToBasket = async (item) => {
		if (item) {
			if (item.is_gift) {
				this.items.push(item);
				if (this.has_multi_basket) {
					this.multi_basket_items.push({
						restaurant: this.restaurant,
						items: [item],
						status: 'NEW',
						tax: 0,
					});
				}
			} else {
				if (this.has_multi_basket) {
					const foundRestaurant = this.multi_basket_items.find((order) => order.restaurant.id == this.restaurant.id);
					if (!foundRestaurant) {
						this.multi_basket_items.push({
							restaurant: this.restaurant,
							items: [item],
							status: 'NEW',
							tax: 0,
						});
					} else {
						let foundItem = foundRestaurant.items.find((fItm) => fItm.item.sku === item.item.sku && fItm.instructions === item.instructions);
						let foundItemIndex = foundRestaurant.items.findIndex((fItm) => fItm.item.sku === item.item.sku);
						if (foundItem && foundItemIndex !== -1) {
							if (foundItem.selectedChoices.length === 0 && item.selectedChoices.length === 0) {
								this.changeItemQuantity(foundItemIndex, item.quantity, true, this.restaurant.id);
							} else {
								let itmSkus = this.selectedChoicesSkus(foundItem.selectedChoices);
								let itemSkus = this.selectedChoicesSkus(item.selectedChoices);
								if (checkIdenticalArrays(itmSkus, itemSkus)) {
									this.changeItemQuantity(foundItemIndex, item.quantity, true, this.restaurant.id);
								} else {
									foundRestaurant.items.push(item);
								}
							}
						} else {
							foundRestaurant.items.push(item);
						}
					}
				} else {
					if (this.items.length > 0) {
						let foundItem = this.items.find((fItm) => fItm.item.sku === item.item.sku && fItm.instructions === item.instructions);
						let foundItemIndex = this.items.findIndex((fItm) => fItm.item.sku === item.item.sku && fItm.instructions === item.instructions);
						if (foundItem && foundItemIndex !== -1) {
							if (foundItem.selectedChoices.length === 0 && item.selectedChoices.length === 0) {
								this.changeItemQuantity(foundItemIndex, item.quantity, true);
							} else {
								let itmSkus = this.selectedChoicesSkus(foundItem.selectedChoices);
								let itemSkus = this.selectedChoicesSkus(item.selectedChoices);
								if (checkIdenticalArrays(itmSkus, itemSkus)) {
									this.changeItemQuantity(foundItemIndex, item.quantity, true);
								} else {
									this.items.push(item);
								}
							}
						} else {
							this.items.push(item);
						}
					} else {
						this.items.push(item);
					}
				}
			}

			this.instanceStateChanged();
			this.clearAllDiscounts();
			if (getConfig().flags.hasTaxBreakdown && this.tax_breakdown.length > 0) {
				this.setTaxBreakdown([]);
				store.dispatch({ type: SET_COMMON_PROP, key: 'taxCalculated', value: false });
			}
		}
	};

	removeFromBasket = (itemIndex) => {
		if (isDefined(itemIndex) && isDefined(this.items[itemIndex])) {
			this.items = this.items.filter((item, index) => index !== itemIndex);

			this.instanceStateChanged();
			this.clearAllDiscounts();
		}
	};

	changeItemQuantity = async (index, value = 0, addOn = false, restaurantId) => {
		if (this.has_multi_basket) {
			const selectedRestaurant = this.multi_basket_items.filter((el) => el.restaurant.id == restaurantId);
			let items = restaurantId ? this.multi_basket_items.filter((el) => el.restaurant.id == restaurantId)?.[0]?.items : this.items;
			if (isDefined(index) && isDefined(items[index])) {
				let item = items[index];
				if (addOn) {
					item.quantity += value;
				} else {
					item.quantity = value;
				}

				if (item.quantity <= 0) {
					if (restaurantId) {
						const selectedBasket = this.multi_basket_items.filter((el) => el.restaurant.id == restaurantId)[0];
						selectedBasket.items = selectedBasket.items.filter((i, basketIndex) => basketIndex !== index);
						if (selectedBasket.items.length == 0) {
							this.multi_basket_items = this.multi_basket_items.filter((el) => el.restaurant.id !== restaurantId);
						}
					} else {
						this.items = items.filter((i, basketIndex) => basketIndex !== index);
					}
					let allergenIndex = this.allergen_data.findIndex((data) => data[1].sku === item.item.sku);
					if (allergenIndex > -1) {
						let newArr = [...this.allergen_data];
						newArr.splice(allergenIndex, 1);
						this.allergen_data = newArr;
					}
				}
			}
		} else {
			if (isDefined(index) && isDefined(this.items[index])) {
				let item = this.items[index];
				if (addOn) {
					item.quantity += value;
				} else {
					item.quantity = value;
				}
				if (item.quantity <= 0) {
					this.items = this.items.filter((i, basketIndex) => basketIndex !== index);
					let allergenIndex = this.allergen_data.findIndex((data) => data[1].sku === item.item.sku);
					if (allergenIndex > -1) {
						let newArr = [...this.allergen_data];
						newArr.splice(allergenIndex, 1);
						this.allergen_data = newArr;
					}
				}
			}
		}
		this.instanceStateChanged();
		this.clearAllDiscounts();
		if (getConfig().flags.hasTaxBreakdown && this.tax_breakdown.length > 0) {
			this.setTaxBreakdown([]);
			store.dispatch({ type: SET_COMMON_PROP, key: 'taxCalculated', value: false });
		}
	};

	changeSelectedCard = (cardToken = null) => {
		if (cardToken) {
			this.selectedCard = cardToken;
			this.instanceStateChanged();
		}
	};

	// e.g. points = 700
	// that is 7eur for the ration 100
	applyPoints = (points = zero, currentAvailableBalace = zero, cb) => {
		const { redeemPointsMin } = getConfig().general;
		points = parseInt(points);
		// check if applied points don't overcomes users available_balance
		let pointsBalance = currentAvailableBalace - points;
		if (pointsBalance < 0) {
			this.toastMessage(this.translate('You have no points avaliable'));
			return;
		}

		// prevent user to apply greater points amount then order total (e.g. total: 7 gbp, applied_points_value: 8 gbt)
		// this.calculatePointsAppliedPrice(this.pointsApplied) -- reset total to the state without applied points
		const newTotal = new BigNumber(this.total).plus(this.calculatePointsAppliedPrice(this.pointsApplied)).minus(this.calculatePointsAppliedPrice(points)).toNumber();
		if (newTotal < 0) {
			this.toastMessage(this.translate(`Minimum Points redemption is ${redeemPointsMin}. Please add more items to your basket. `));
			return;
		}

		this.pointsApplied = points;
		this.instanceStateChanged();
		if (cb) {
			cb();
		}
	};
	formatTableItems = (data) => {
		var alteredItems = [];
		alteredItems = data.map((el) => ({ ...el, subLineItems: [] }));
		var itemsToRemove = [];
		for (let i = 0; i < data.length; i++) {
			var subItemsToRemove = [];
			for (let j = data[i].subLineItems.length - 1; j >= 0; j--) {
				if (data[i].subLineItems[j].quantity < 0) {
					const positiveItems = data[i].subLineItems.findIndex(
						(item, index) =>
							item.itemSku == data[i].subLineItems[j].itemSku && item.quantity > 0 && !subItemsToRemove.includes(index) && item.unitAmount == data[i].subLineItems[j].unitAmount,
					);
					if (positiveItems !== -1) {
						subItemsToRemove.push(positiveItems, j);
					}
				}
			}
			alteredItems[i].subLineItems = data[i].subLineItems.map((el, index) => (subItemsToRemove.includes(index) ? undefined : el)).filter(Boolean);

			if (data[i].quantity < 0) {
				const positiveItems = alteredItems.findIndex(
					(item, index) =>
						item.itemSku == alteredItems[i].itemSku &&
						item.quantity > 0 &&
						item.subLineItems.length == alteredItems[i].subLineItems.length &&
						!itemsToRemove.includes(index) &&
						item.unitAmount == alteredItems[i].unitAmount,
				);
				if (positiveItems !== -1) {
					itemsToRemove.push(positiveItems, i);
				}
			}
		}
		return alteredItems.map((el, index) => (itemsToRemove.includes(index) ? undefined : el)).filter(Boolean);
	};
	setBillPayData = (bill_data) => {
		this.bill_data = bill_data;
		this.table_items = bill_data?.bill_data?.salesEntries ? this.formatTableItems(bill_data.bill_data.salesEntries) : [];
		this.paid_amount = bill_data.total_paid;
		this.amount_left_to_pay = bill_data.total_due;
		this.bill_total = bill_data.total_due_orig;
		this.table_members = bill_data.total_members_to_pay;
		this.table_members_paid = bill_data.total_members_paid_for;
		this.selected_items = bill_data.selected_items;
		this.table_pay_method = parseInt(bill_data.pay_method) || 0;
		this.table_name = parseInt(bill_data.table_number);
		this.instanceStateChanged();
	};
	setTableItems = (items) => {
		this.table_items = this.formatTableItems(items);
	};
	getBillTotal = () => this.formatPrice(this.bill_total / 100, 0);
	getAmountLeftToPay = () => this.formatPrice(this.bill_data.total_due / 100, 0);
	getPaidAmount = () => this.formatPrice(this.bill_data.total_paid / 100, 0);

	setBillPayServiceCharge = (service_charge, service_charge_applied) => {
		if (!service_charge_applied) {
			this.bill_pay_service_charge = service_charge;
			this.bill_pay_service_charge_applied = this.round(new BigNumber(this.table_payment_amount).div(100).times(this.bill_pay_service_charge).toNumber(), 2);
		} else {
			this.bill_pay_service_charge = null;
			this.bill_pay_service_charge_applied = service_charge_applied;
		}
		this.calculateBillPaymentAmount();
		this.instanceStateChanged();
	};

	setTablePaymentAmount = (value) => {
		this.table_payment_amount = parseInt(this.round(value, 2));
		this.instanceStateChanged();
	};
	getTotalPlusServiceCharge = () => {
		return this.formatPrice(this.round((this.table_payment_amount + this.bill_pay_service_charge_applied) / 100, 2), true);
	};
	getBillPayServiceChargeValue = () => {
		return this.formatPrice(this.round(this.bill_pay_service_charge_applied / 100, 2));
	};
	getLeadUser = () => this.bill_data.lead_user;
	getTableMembers = () => this.table_members;

	getBillTableNumber = () => this.table_name;
	setPortionToPay = (int) => {
		this.table_members_to_pay = parseInt(int);
		this.getTableAmountToPay();
		if (this.bill_pay_service_charge !== null) {
			this.bill_pay_service_charge_applied = this.round(new BigNumber(this.table_payment_amount).div(100).times(this.bill_pay_service_charge).toNumber(), 2);
		}

		this.instanceStateChanged(true, false);
	};
	getTableItems = () => {
		return this.table_items;
	};
	getPayTableNumber = () => {
		return parseInt(this.bill_data.table_number);
	};
	createBillPay = (paymentType, paymentWebType, cb) => {
		this.calculateBillPaymentAmount();
		store.dispatch({ type: SEND_TABLE_BILL, paymentType, paymentWebType, cb });
	};
	parseBillPayload = (paymentType = null, paymentWebType) => {
		if (paymentType || paymentWebType) {
			this.selectedCard =
				paymentType === 'apple'
					? 'Apple Pay'
					: paymentType === 'google'
						? 'Google Pay'
						: paymentType === 'collectedPay'
							? 'Pay on collection'
							: paymentType === 'payOnAccount'
								? 'Pay On Account'
								: paymentWebType === 'APPLE_PAY'
									? 'Apple Pay'
									: paymentWebType === 'BROWSER'
										? 'Google Pay'
										: paymentWebType === 'GOOGLE_PAY'
											? 'Google Pay'
											: paymentWebType === 'LINK'
												? 'Google Pay'
												: paymentWebType === 'BROWSER_CARD'
													? 'Google Pay'
													: null;
		}

		return {
			pos_location_id: this.restaurant.pos_location_id,
			payment_amount: this.table_payment_amount,
			payment_token: this.selectedCard,
			pay_method: parseInt(this.bill_data.pay_method),
			loyalty_discount_applied: 0,
			applied_vouchers: [],
			service_charge_applied: this.bill_pay_service_charge_applied,
			service_charge_percentage: this.bill_pay_service_charge,

			paid_items: this.paid_items,
			table_bill_id: this.bill_data.id,
			total_members_to_pay: this.table_members,
			people_paying_for: this.table_members_to_pay,
			table_name: this.table_name,
		};
	};

	getTableAmountToPay = () => {
		if (this.table_members_to_pay && this.table_members) {
			if (parseInt(this.table_members) - this.table_members_paid == this.table_members_to_pay) {
				this.table_payment_amount = parseInt(this.amount_left_to_pay);
			} else {
				this.table_payment_amount = parseInt(this.round(new BigNumber(this.bill_total).div(this.table_members).times(this.table_members_to_pay).toNumber(), 2));
			}

			return new BigNumber(this.table_payment_amount).div(100).toNumber().toFixed(2);
		} else if (this.amount_left_to_pay) {
			return new BigNumber(this.amount_left_to_pay).toNumber().toFixed(2);
		} else {
			return 0;
		}
	};

	// GETTERS ----------------------------------------------------------------------------------------
	toObject = () => {
		const {
			menu_id,
			items,
			restaurant,
			mobile,
			mobile_code,
			mobile_value,
			collection_time,
			_collection_time,
			total,
			discount_total,
			applied_vouchers,
			applicable_vouchers,
			applied_gift_cards,
			selectedCard,
			pointsApplied,
			subTotal,
			order_type,
			delivery_option,
			delivery_address,
			pick_up_point,
			delivery_price,
			process_fee_value,
			menu,
			min_order,
			delivery_min_order,
			allergen_data,
			cutoffTime,
			table_name,
			service_percentage,
			service_charge,
			service_charge_value,
			passed_order,
			is_asap,
			next_collection_time,
			is_gift,
			processing_fee_description,
			already_paid,
			bill_data,
			portion_to_pay,
			amount_left_to_pay,
			table_pay_method,
			table_items,
			bill_total,
			paid_amount,
			table_members,
			table_members_paid,
			table_payment_amount,
			table_members_to_pay,
			paid_items,
			table_items_price,
			selected_items,
			bill_pay_service_charge,
			bill_pay_service_charge_applied,
			bill_pay_total,
			restaurant_delivery_price,
			custom_field,
			uuid,
			gift_voucher_data,
			has_multi_basket,
			multi_basket_items,
			additional_data,
			tax_breakdown,
			total_tax,
			validation_id,
			channel_link_id,
			channel_name,
			send_to_deliverect,
			process_fee_amount
		} = this;
		return {
			items,
			restaurant,
			mobile,
			mobile_code,
			mobile_value,
			collection_time,
			_collection_time,
			discount_total,
			applied_vouchers,
			applicable_vouchers,
			applied_gift_cards,
			total,
			selectedCard,
			pointsApplied,
			subTotal,
			order_type,
			delivery_option,
			delivery_address,
			pick_up_point,
			delivery_price,
			process_fee_value,
			menu,
			min_order,
			delivery_min_order,
			allergen_data,
			cutoffTime,
			table_name,
			service_percentage,
			service_charge,
			service_charge_value,
			passed_order,
			is_asap,
			next_collection_time,
			is_gift,
			processing_fee_description,
			menu_id,
			already_paid,
			bill_data,
			portion_to_pay,
			amount_left_to_pay,
			table_pay_method,
			table_items,
			bill_total,
			paid_amount,
			table_members,
			table_members_paid,
			table_payment_amount,
			table_members_to_pay,
			paid_items,
			table_items_price,
			selected_items,
			bill_pay_service_charge,
			bill_pay_service_charge_applied,
			bill_pay_total,
			restaurant_delivery_price,
			custom_field,
			uuid,
			gift_voucher_data,
			has_multi_basket,
			multi_basket_items,
			additional_data,
			tax_breakdown,
			total_tax,
			validation_id,
			channel_link_id,
			channel_name,
			send_to_deliverect,
			process_fee_amount
		};
	};

	itemsCount = () => {
		if (this.has_multi_basket) {
			return this.multi_basket_items.reduce((acc, obj) => acc + obj.items.length, 0);
		} else {
			return (this.items || []).length;
		}
	};
	itemsCountAll = () => {
		if (this.has_multi_basket) {
			return this.multi_basket_items.reduce((acc, obj) => acc + obj.items.reduce((a, b) => a + b.quantity, 0), 0);
		} else {
			return this.items.map((item) => item.quantity).reduce((a, b) => a + b, 0);
		}
	};

	getDiscountTotal = () => this.discount_total;

	_getDiscountTotal = () => this.formatPrice(this.getDiscountTotal());

	getItems = () => this.items || [];

	getTotal = () => this.total;

	_getTotal = (inlucdeZero) => this.formatPrice(this.getTotal(), inlucdeZero);

	getSubTotal = () => this.subTotal;

	_getSubTotal = () => this.formatPrice(this.getSubTotal());

	getRestauranName = () => (this.restaurant && this.restaurant.name ? this.restaurant.name : emptyStr);

	getRestaurant = () => this.restaurant || null;

	getRestaurantServiceCharge = () => {
		if (this.restaurant.service_charge) {
			return JSON.parse(this.restaurant.service_charge);
		} else {
			return false;
		}
	};
	getMenu = () => this.menu || null;

	getMobile = () => this.mobile || null;

	getMobileCode = () => this.mobile_code || null;

	getMobileValue = () => this.mobile_value || null;

	getOrderDate = (format = null) => {
		return this.collection_time
			? moment
				.unix(this.collection_time)
				.tz(getConfig().timezone)
				.format(format || 'dddd Do MMMM')
			: emptyStr;
	};

	getOrderTime = (format = null) =>
		this.collection_time
			? moment
				.unix(this.collection_time)
				.tz(getConfig().timezone)
				.format(format || 'LT')
			: emptyStr;

	getSelectedCurrency = () => (this.restaurant && this.restaurant.currency ? this.restaurant.currency : getConfig().general.defaultCurrency);

	getAppliedVoucher = () => this.applied_vouchers || [];

	getApplicableVocuher = () => this.applicable_vouchers || [];
	getAppliedGiftCards = () => this.applied_gift_cards || [];
	setAppliedGiftCards = (data) => {
		this.applied_gift_cards = data;
		this.instanceStateChanged();
	};
	getAppliedGiftCardsValue = () => this.applied_gift_cards.reduce((acc, data) => acc + data.value, 0);
	getCurrency = () => {
		const defaultCurrencySimbol = getConfig().general.defaultCurrencySimbol || '';
		const currency = this.getSelectedCurrency();
		const empty = { label: defaultCurrencySimbol, beforeNumber: true, includeSpace: false }; // When no value}
		const currency_symbols = {
			...empty,
			USD: { label: '$', beforeNumber: true, includeSpace: false }, // US Dollar
			EUR: { label: '€', beforeNumber: true, includeSpace: false }, // Euro
			GBP: { label: '£', beforeNumber: true, includeSpace: false }, // British Pound Sterling
			AU: { label: '$', beforeNumber: true, includeSpace: false }, // Australian dollar
			CHF: { label: '', beforeNumber: false, includeSpace: false },
		};
		const currency_name = currency.toUpperCase();
		if (currency_symbols[currency_name] !== undefined) {
			return currency_symbols[currency_name];
		} else {
			return empty;
		}
	};
	setGift = (data, saveToStore = true) => {
		if (data) {
			this.is_gift = true;
			this.gift_voucher_data = data;
		} else {
			this.is_gift = false;
		}
		if (saveToStore) {
			this.instanceStateChanged(true, false);
		}
	};
	isGift = () => {
		return this.is_gift;
	};
	getCountry = () => (this.restaurant && this.restaurant.country_code ? this.restaurant.country_code : getConfig().general.default_country_code);

	getAppliedPoints = () => this.pointsApplied;

	getItemsForApplePay = (profile) => {
		return (this.items || [])
			.map((item) => ({
				label: this.getProductName(item.item, profile),
				amount: this.calculateItemPrice(item),
			}))
			.filter((item) => item.amount > 0);
	};

	getItemsForWebPay = (clientName, isBillPay) => {
		let productName = clientName;

		return {
			label: productName,
			amount: isBillPay ? Math.ceil(new BigNumber(this.table_payment_amount + this.bill_pay_service_charge_applied).toNumber()) : Math.ceil(new BigNumber(this.total).times(100).toNumber()),
		};
	};

	getProductName = (item = {}, profile) => {
		let productName = emptyStr;
		const locale = getLocale(profile);
		if (item.productName) {
			productName = item.productName;
			if (item.itemRichData && item.itemRichData.texts) {
				const translation = item.itemRichData.texts.find((i) => i.locale === locale);
				if (translation && translation.friendlyDisplayName !== emptyStr) {
					productName = translation.friendlyDisplayName;
				}
			}
		}
		return productName;
	};

	getProductDescription = (item = {}, profile) => {
		let description = item.description || emptyStr;
		const locale = getLocale(profile);
		if (item.itemRichData && item.itemRichData.texts) {
			const translation = item.itemRichData.texts.find((i) => i.locale === locale);
			if (translation && translation.description !== emptyStr) {
				description = translation.description;
			}
		}
		return description;
	};

	getOrderType = (order = null) => {
		if (order) {
			if (order.delivery) {
				return 'charter-delivery';
			}
			if (order.take_away) {
				return 'collection';
			}
			if (order.eat_in) {
				return 'table';
			}
			if (order.pick_up_point) {
				return 'pick-up-point';
			}
			if (order.scheduled_collection) {
				return 'scheduled-collection';
			}
			if (order.pick_up_at_counter) {
				return 'pick-up-at-counter';
			}
			if (order.scheduled_delivery) {
				return 'scheduled-delivery';
			}
			if (order.browse_menu) {
				return 'browse-menu';
			}
			return null;
		} else {
			switch (this.order_type) {
				case 'delivery':
					return 'Delivery';
				case 'collection':
					return 'Click & Collect';
				case 'table':
					return 'Table';
				case 'pick-up-point':
					return 'Outpost Drop-Off';
				case 'charter-delivery':
					return 'Delivery';
				case 'scheduled-collection':
					return 'scheduled-collection';
				case 'pick-up-at-counter':
					return 'pick-up-at-counter';
				case 'browse-menu':
					return 'browse-menu';
				case 'scheduled-delivery':
					return 'scheduled-delivery';
				default:
					return '';
			}
		}
	};

	getRestaurantDeliveryPrice = () => {
		return parseFloat(this.restaurant_delivery_price) || 0;
	};

	getCustomFieldValue = () => {
		return this.custom_field;
	};
	// METHODS ----------------------------------------------------------------------------------------

	// get current state of the instance as JSON object
	export = () => {
		const {
			menu_id,
			items,
			restaurant,
			mobile,
			mobile_code,
			mobile_value,
			collection_time,
			_collection_time,
			applied_vouchers,
			applicable_vouchers,
			applied_gift_cards,
			selectedCard,
			pointsApplied,
			order_type,
			delivery_option,
			delivery_address,
			pick_up_point,
			delivery_price,
			process_fee_value,
			menu,
			min_order,
			delivery_min_order,
			allergen_data,
			cutoffTime,
			table_name,
			service_percentage,
			service_charge,
			service_charge_value,
			passed_order,
			is_asap,
			next_collection_time,
			is_gift,
			processing_fee_description,
			already_paid,
			bill_data,
			portion_to_pay,
			amount_left_to_pay,
			table_pay_method,
			bill_total,
			table_items,
			paid_amount,
			table_members,
			table_members_paid,
			table_payment_amount,
			table_members_to_pay,
			paid_items,
			table_items_price,
			selected_items,
			bill_pay_service_charge,
			bill_pay_service_charge_applied,
			bill_pay_total,
			restaurant_delivery_price,
			custom_field,
			uuid,
			gift_voucher_data,
			has_multi_basket,
			multi_basket_items,
			additional_data,
			tax_breakdown,
			total_tax,
			validation_id,
			channel_link_id,
			channel_name,
			send_to_deliverect, } = this;
		return {
			items,
			restaurant,
			mobile,
			mobile_code,
			mobile_value,
			collection_time,
			_collection_time,
			applied_vouchers,
			applicable_vouchers,
			applied_gift_cards,
			selectedCard,
			pointsApplied,
			order_type,
			delivery_option,
			delivery_address,
			pick_up_point,
			delivery_price,
			process_fee_value,
			menu,
			min_order,
			delivery_min_order,
			allergen_data,
			cutoffTime,
			table_name,
			service_percentage,
			service_charge,
			service_charge_value,
			passed_order,
			is_asap,
			next_collection_time,
			is_gift,
			processing_fee_description,
			menu_id,
			already_paid,
			bill_data,
			portion_to_pay,
			amount_left_to_pay,
			table_pay_method,
			bill_total,
			table_items,
			paid_amount,
			table_members,
			table_members_paid,
			table_payment_amount,
			table_members_to_pay,
			paid_items,
			table_items_price,
			selected_items,
			bill_pay_service_charge,
			bill_pay_service_charge_applied,
			bill_pay_total,
			restaurant_delivery_price,
			custom_field,
			uuid,
			gift_voucher_data,
			has_multi_basket,
			multi_basket_items,
			additional_data,
			tax_breakdown,
			total_tax,
			validation_id,
			channel_link_id,
			channel_name,
			send_to_deliverect,
		};
	};

	//save instance to the local storage
	//this method should track ALL instance changes (must be called in every SETTER)
	saveInstance = async () => {
		// save to local storage
		await asyncStorage.setItem('basket', JSON.stringify(this.export()));
		this.log('Saved to localStorage');
	};

	import = async (basketObject = null, is_multi_basket) => {
		//reset current instance to the initial state
		this.create(is_multi_basket);

		if (!basketObject) {
			let storageBasket = await asyncStorage.getItem('basket');
			if (storageBasket) {
				try {
					if (isDefined(storageBasket)) {
						basketObject = JSON.parse(storageBasket);
					}
				} catch (e) {
					this.log('Error: Parsing basket from storage.');
				}
			}
		}

		//restore all relevent instance properties from provided object
		if (isDefined(basketObject)) {
			Object.keys(basketObject).forEach((key) => {
				this[key] = basketObject[key];
			});

			if (basketObject.restaurant && basketObject.restaurant.business_location_id && basketObject.restaurant.menu_id) {
				const { menu_id, business_location_id } = basketObject.restaurant;
				const _menu_id = this.menu || menu_id;
				store.dispatch(getIkentooMenu(_menu_id, business_location_id, false));
			}

			//recalculate totals and skip saving to the local storage
			this.instanceStateChanged(false);

			this.log('Imported from localStorage');

			this._isCollectionTimeStillValid();
		} else {
			this.log("LocalStorage basket don't exists.");
		}
	};

	log = (message = null) => {
		// eslint-disable-next-line no-console
		console.log('Basket: ', message ? '(' + message + ')' : '', this.toObject());
	};
	calculateBillPaymentAmount = () => {
		const total = new BigNumber(zero);
		this.bill_pay_total = total.plus(this.table_payment_amount).plus(this.bill_pay_service_charge_applied).toNumber();
	};
	calculateTotal = () => {
		const total = new BigNumber(zero);
		this.total = total
			.minus(this.getAppliedGiftCardsValue())
			.plus(this.getTotalTaxBreakdown())
			.plus(this.subTotal)
			.plus(this.calculatePointsAppliedPrice(null, true))
			.plus(this.calculateAppliedVocuhersPrice(true))
			.toNumber();
	};

	calculateSubTotal = () => {
		let subTotal = new BigNumber(zero);
		if (this.is_gift) {
			this.items.forEach((basketItem) => {
				subTotal = subTotal.plus(this.calculateItemPrice(basketItem));
			});
		} else {
			if (this.has_multi_basket) {
				subTotal = subTotal.plus(
					this.multi_basket_items.reduce((acc, obj) => {
						const itemPrices = obj.items.reduce((itemAcc, itemObj) => itemAcc + this.calculateItemPrice(itemObj, true), 0);
						return acc + itemPrices;
					}, 0),
				);
			} else {
				this.items.forEach((basketItem) => {
					subTotal = subTotal.plus(this.calculateItemPrice(basketItem));
				});
			}
		}

		this.subTotal = subTotal.plus(this.calculateDeliveryCharge(subTotal)).plus(this.calculateServiceCost());
		this.subTotal = this.subTotal.plus(this.calculateProcessingFee(this.isGift()));

		this.subTotal = this.subTotal.toNumber().toFixed(2);

		return subTotal.toNumber().toFixed(2);
	};

	calculateItemPrice = (basketItem, includeSubItems = true) => {
		const { item, quantity, selectedChoices } = basketItem;
		let itemPrice = new BigNumber(zero);
		let menuDealTotal = new BigNumber(zero);
		let selectedChoicesPrice = new BigNumber(zero);

		if (item && item.productPrice) {
			itemPrice = parseFloat(item.productPrice);
		}
		if (includeSubItems && selectedChoices && selectedChoices.length > 0) {
			//go throught all groups
			selectedChoices.forEach((menuDealGroup) => {
				if (menuDealGroup && menuDealGroup.length > 0) {
					//go throught all selected choices
					menuDealGroup.forEach((selectedChoice) => {
						selectedChoicesPrice = new BigNumber(parseFloat(selectedChoice.productPrice));
						if (selectedChoice.productPrice && selectedChoice.productPrice !== '') {
							menuDealTotal = menuDealTotal.plus(selectedChoicesPrice.times(selectedChoice.quantity));
						}
					});
				}
			});
		}
		return new BigNumber(itemPrice).plus(menuDealTotal).times(quantity).toNumber();
	};

	_calculateItemPrice = (basketItem, includeSubItems, inlucdeZero) => this.formatPrice(this.calculateItemPrice(basketItem, includeSubItems), inlucdeZero);

	// parse sub item as items and then use existing methods
	calculateSubItemPrice = (subItem, quantity = 1) => {
		const item = {
			quantity,
			item: subItem,
		};
		return this.calculateItemPrice(item);
	};

	_calculateSubItemPrice = (subItem, quantity) => this.formatPrice(this.calculateSubItemPrice(subItem, quantity));

	calculateItemPriceByIndex = (itemIndex, includeSubItems) => {
		if (isDefined(itemIndex) && this.items[itemIndex]) {
			return this.calculateItemPrice(this.items[itemIndex], includeSubItems);
		} else {
			return zero;
		}
	};

	_calculateItemPriceByIndex = (itemIndex, includeSubItems) => this.formatPrice(this.calculateItemPriceByIndex(itemIndex, includeSubItems));

	// use appliablePoints to calculate pointsApplieddPrice without need to change instace and then make calculations
	calculatePointsAppliedPrice = (appliablePoints = null, shouldBeNagative = false) => {
		const points = isDefined(appliablePoints) ? appliablePoints : this.pointsApplied;
		if (points > zero) {
			const pointsRealValue = new BigNumber(points).div(pointRatio); //currency value
			return pointsRealValue.times(shouldBeNagative ? negativeOne : positiveOne).toNumber();
		}
		return zero;
	};

	_calculatePointsAppliedPrice = (appliablePoints, shouldBeNagative, inlucdeZero) => this.formatPrice(this.calculatePointsAppliedPrice(appliablePoints, shouldBeNagative), inlucdeZero);

	formatPrice = (price, inlucdeZero = false) => {
		if (isDefined(price)) {
			if (typeof price === 'string') {
				price = parseFloat(price);
			}
			if (price !== 0 || inlucdeZero) {
				let retValue = '';
				const currencyObj = this.getCurrency();
				let currencySign = currencyObj.label;
				currencySign = currencyObj.includeSpace ? (currencyObj.beforeNumber ? currencySign + ' ' : ' ' + currencySign) : currencySign;
				retValue += price < 0 ? '-' : '';

				// before number
				retValue += currencyObj.beforeNumber ? currencySign : '';
				const fullPrice = typeof price === 'string' ? price : price < 0 ? price * negativeOne : price;
				retValue += this.round(fullPrice, 2).toFixed(2);
				//after number
				retValue += currencyObj.beforeNumber ? '' : currencySign;
				return retValue;
			}
		}
		return emptyStr;
	};

	round(v, d) {
		return parseFloat(Math.round(v.toFixed(d + 1) + 'e' + d) + 'e-' + d);
	}
	getProductCardPrice = (item) => {
		if (!item.productPrice || Number(item.productPrice) === 0 && !getConfig().flags.removeModifierPriceIfPriceIsZero) {
			const prices = [];
			if (item.menuDealGroups) {
				for (let i = 0; i < item.menuDealGroups.length; i++) {
					for (let j = 0; j < item.menuDealGroups[i].items.length; j++) {
						if (this.isProductEnabled(item.menuDealGroups[i].items[j])) {
							prices.push(Number(item.menuDealGroups[i].items[j].productPrice));
						}
					}
				}
				return prices.filter(Boolean)[0];
			}
		} else {
			return item.productPrice || 0;
		}
	};
	isProductUnsnoozed = (item) => (item && isDefined(item.sku) && this.restaurant && [...(this.restaurant.snoozed_skus || [])].indexOf(item.sku) !== -1 ? false : true);
	isProductJustEnabled = (item) => (item && isDefined(item.sku) && this.restaurant && [...(this.restaurant.disabled_skus || [])].indexOf(item.sku) !== -1 ? false : true);
	isProductEnabled = (item) =>
		item && isDefined(item.sku) && this.restaurant && [...(this.restaurant.disabled_skus || []), ...(this.restaurant.snoozed_skus || [])].indexOf(item.sku) !== -1 ? false : true;

	isChoicesGroupValid = (item) => item.items.filter((i) => this.isProductEnabled(i)).length > 0;

	calculateVouchersPrice = (vouchers = [], applicableVouchers = [], shouldBeNagative = false) => {
		let cost = zero;
		let voucherWithDiscountInfo = {};
		vouchers.forEach((applied_voucher) => {
			if (vouchersType === 3) {
				voucherWithDiscountInfo = applicableVouchers.find((applicable_vocuher) =>
					applicable_vocuher.type && applicable_vocuher.type === 1
						? applicable_vocuher.id === applied_voucher.id
						: applied_voucher.reward.id
							? applicable_vocuher.id === applied_voucher.reward.id
							: applicable_vocuher.id === applied_voucher.reward_sku,
				);
			} else {
				voucherWithDiscountInfo = applicableVouchers.find((applicable_vocuher) => applicable_vocuher.id === applied_voucher.id);
			}
			if (voucherWithDiscountInfo) {
				cost = new BigNumber(cost).plus(new BigNumber(voucherWithDiscountInfo.cost)).toNumber();
			}
		});
		return new BigNumber(cost)
			.times(shouldBeNagative ? negativeOne : positiveOne)
			.div(pointRatio)
			.toNumber();
	};

	calculateAppliedVocuhersPrice = (shouldBeNagative = false) => {
		return this.calculateVouchersPrice(this.applied_vouchers, this.applicable_vouchers, shouldBeNagative);
	};

	_calculateAppliedVocuhersPrice = (shouldBeNagative, inlucdeZero) => this.formatPrice(this.calculateAppliedVocuhersPrice(shouldBeNagative), inlucdeZero);

	canVoucherBeApplied = (voucher, applicableVoucher, shouldBeNagative = true) => {
		const vouchersPrice = this.calculateVouchersPrice([voucher], [applicableVoucher], shouldBeNagative);
		return this.total >= vouchersPrice;
	};

	instanceStateChanged = (saveToStorage = true, skipStoreUpdate = false) => {
		this.calculateSubTotal();

		this.calculateTotal();
		if (saveToStorage) {
			this.saveInstance();
		}
		if (!skipStoreUpdate) {
			store.dispatch({ type: SET_ORDERS_PROP, key: 'basketUpdated', value: Date.now() });
		}
	};

	clearAllDiscounts = (clearPoints = true, clearVouchers = true) => {
		if (clearPoints) {
			this.pointsApplied = zero;
		}
		if (clearVouchers) {
			this.applied_vouchers = [];
			this.applicable_vouchers = [];
		}

		this.instanceStateChanged();
	};

	validateItem = (basketItem) => {
		const { item, selectedChoices } = basketItem;
		let errors = item && item.menuDealGroups ? Array((item.menuDealGroups || []).length).fill(null) : [];
		let errorCount = 0;

		if (item) {
			if (item.menuDealGroups && item.menuDealGroups.length > 0) {
				if (selectedChoices && selectedChoices.length > 0) {
					if (item.menuDealGroups.length === selectedChoices.length) {
						item.menuDealGroups.forEach((menuDealGroup, groupIndex) => {
							const selectedChoiceGroup = selectedChoices[groupIndex];
							const { mustSelectAnItem, multiSelectionPermitted, min, max } = menuDealGroup;
							if (this.isChoicesGroupValid(menuDealGroup)) {
								const selectedChoicesQuantities = selectedChoiceGroup.reduce((total, currentChoice) => {
									total += currentChoice.quantity || 0;
									return total;
								}, 0);

								if (mustSelectAnItem && selectedChoiceGroup.length === 0) {
									errors[groupIndex] = errorMessages.requiredOneItem;
									errorCount += 1;
								}
								if (!multiSelectionPermitted && selectedChoiceGroup.length > 1) {
									errors[groupIndex] = errorMessages.oneOrMode;
									errorCount += 1;
								}
								if (multiSelectionPermitted && isDefined(max) && max > 0 && selectedChoiceGroup.length > 0 && selectedChoicesQuantities > max) {
									errors[groupIndex] = errorMessages.maxItems;
									errorCount += 1;
								}
								if (multiSelectionPermitted && isDefined(min) && min > 0 && selectedChoiceGroup.length > 0 && selectedChoicesQuantities < min) {
									errors[groupIndex] = errorMessages.minItems + min;
									errorCount += 1;
								}
							}
						});
					}
				}
			}
		}
		return {
			errors,
			errorCount,
		};
	};

	isMinimumOrderTotalSatisfied = (showToast = false) => {
		const minOrder = this.getMinOrder();
		const total = this.getSubTotal();
		if (parseFloat(minOrder) > 0 && parseFloat(minOrder) > parseFloat(total)) {
			if (showToast) {
				this.toastMessage(`${this.translate('Minimum order must be')} ${this.formatPrice(minOrder)}`, 'warning');
			}
			return false;
		}
		return true;
	};

	createOrder = (paymentType = null, paymentWebType = null, cb) => {
		const items = this.getItems();
		const unvaibleItems = items.filter((basketItem) => {
			const { item } = basketItem;
			if (!this.isProductEnabled(item)) {
				const newBasketItems = this.getItems();
				newBasketItems.map((newBasketItem, index) => {
					const newItem = newBasketItem.item;
					if (newItem.sku === item.sku) {
						this.removeFromBasket(index);
						this.toastMessage(`${item.productName} ${this.translate('is not available anymore')}!`, 'warning');
					}
				});
				return true;
			}
		});
		if (unvaibleItems.length === 0) {
			if (!isEmptyObject(this.getDeliveryOption()) && this.getDeliveryOption().id === 'delivery') {
				if (this.isMinimumOrderTotalSatisfied()) {
					store.dispatch({ type: CREATE_ORDER, paymentType, paymentWebType, cb });
				}
			} else {
				store.dispatch({ type: CREATE_ORDER, paymentType, paymentWebType, cb });
			}
		}
	};
	createStripeOrder = (paymentType = null, paymentWebType = null, cb) => {
		if (!isEmptyObject(this.getDeliveryOption()) && this.getDeliveryOption().id === 'delivery') {
			if (this.isMinimumOrderTotalSatisfied()) {
				store.dispatch({ type: CREATE_STRIPE_ORDER, paymentType, paymentWebType, cb });
			}
		} else {
			store.dispatch({ type: CREATE_STRIPE_ORDER, paymentType, paymentWebType, cb });
		}
	};
	createYocoOrder = (paymentType = null, paymentWebType = null, cb) => {
		if (!isEmptyObject(this.getDeliveryOption()) && this.getDeliveryOption().id === 'delivery') {
			if (this.isMinimumOrderTotalSatisfied()) {
				store.dispatch({ type: CREATE_YOCO_ORDER, paymentType, paymentWebType, cb });
			}
		} else {
			store.dispatch({ type: CREATE_YOCO_ORDER, paymentType, paymentWebType, cb });
		}
	};
	calculateItemsPrice = (userId) => {
		const checkedItems = this.table_items.filter((el) =>
			this.selected_items
				.filter((el) => el.user_id == userId)
				.map((x) => x.item_id)
				.includes(el.id),
		);
		const itemsToPay = checkedItems.filter((el) => !this.bill_data.paid_items.map((x) => x.id).includes(el.id));
		this.setTablePaidItems(itemsToPay);
		let price = 0;
		itemsToPay.forEach((item) => {
			price += parseFloat(item.unitAmount);
			if (item.subLineItems) {
				item.subLineItems.forEach((subitem) => {
					price += parseFloat(subitem.unitAmount);
				});
			}
		});

		this.setTablePaymentAmount(price * 100);
		if (this.bill_pay_service_charge !== null) {
			this.bill_pay_service_charge_applied = this.round(new BigNumber(this.table_payment_amount).div(100).times(this.bill_pay_service_charge).toNumber(), 2);
		}
		this.instanceStateChanged();
	};
	getPayEvents = () => {
		return this.bill_data.pay_events;
	};
	setTablePaidItems = (data) => {
		this.paid_items = data;
		// this.instanceStateChanged()
	};
	parseBasketData = (paymentType = null, paymentWebType = null) => {
		const {
			items,
			selectedCard,
			restaurant,
			mobile,
			mobile_code,
			mobile_value,
			collection_time,
			total,
			pointsApplied,
			order_type,
			delivery_option,
			delivery_address,
			pick_up_point,
			delivery_price,
			min_order,
			delivery_min_order,
			allergen_data,
			cutoffTime,
			applicable_vouchers,
			applied_gift_cards,
			table_name,
			service_percentage,
			service_charge,
			passed_order,
			process_fee_value,
			is_asap,
			is_gift,
			already_paid,
			bill_data,
			portion_to_pay,
			amount_left_to_pay,
			table_pay_method,
			bill_total,
			table_items,
			paid_amount,
			table_members,
			table_members_paid,
			table_payment_amount,
			table_members_to_pay,
			paid_items,
			table_items_price,
			selected_items,
			bill_pay_service_charge,
			bill_pay_service_charge_applied,
			bill_pay_total,
			restaurant_delivery_price,
			custom_field,
			uuid,
			gift_voucher_data,
			has_multi_basket,
			multi_basket_items,
			additional_data,
			tax_breakdown,
			total_tax,
			validation_id,
			channel_link_id,
			channel_name,
			send_to_deliverect,
		} = this;
		let errors = [];
		if (isEmptyObject(this.bill_data)) {
			if (this.itemsCount() === 0) {
				errors.push('Your basket is empty');
			}
			if (!restaurant) {
				errors.push('Please select restaurant');
			}

			// if (hasContactDetails && !mobile) {
			// 	errors.push('Please select mobile')
			// }
			if (!collection_time) {
				errors.push('Please select collection time');
			}
		}
		if (paymentType || paymentWebType) {
			this.selectedCard =
				paymentType === 'apple'
					? 'Apple Pay'
					: paymentType === 'google'
						? 'Google Pay'
						: paymentType === 'collectedPay'
							? 'Pay on collection'
							: paymentType === 'driverPay'
								? 'Pay to the driver'
								: paymentWebType === 'APPLE_PAY'
									? 'Apple Pay'
									: paymentWebType === 'BROWSER'
										? 'Google Pay'
										: paymentWebType === 'GOOGLE_PAY'
											? 'Google Pay'
											: paymentWebType === 'iDEAL'
												? 'iDEAL'
												: paymentType === 'iDEAL'
													? 'iDEAL'
													: paymentWebType === 'LINK'
														? 'Google Pay'
														: paymentWebType === 'BROWSER_CARD'
															? 'Google Pay'
															: paymentWebType === 'NativePayment'
																? 'Google Pay'
																: paymentType == 'yoco'
																	? 'yoco'
																	: null;
			this.pay_on_collection = paymentType === 'collectedPay' || paymentType === 'driverPay' ? true : false;
		} else {
			this.selectedCard = selectedCard;
		}
		// if (!cutoffTime) {
		// 	errors.push('Cutoff time empty')
		// }

		if (!this.isCollectionTimeStillValid()) {
			errors.push(errorMessages.basketNotValid);
			this._isCollectionTimeStillValid();
		}

		if (errors.length > 0) {
			errors.forEach((e) => this.toastMessage(this.translate(e)));
			throw errors;
		}
		let rewardCost = 0;
		if (vouchersType === 2) {
			this.applied_vouchers.forEach((i) => (rewardCost += i.cost));
		} else if (vouchersType === 3) {
			if (this.applied_vouchers.type === 2) {
				this.applied_vouchers.forEach((i) => (rewardCost += i.cost));
			}
		}
		const parsedItems = items;
		additional_data.tax_breakdown = tax_breakdown;
		return {
			applicable_vouchers: applicable_vouchers,
			applied_gift_cards,
			items: parsedItems,
			payment_token: this.selectedCard,
			pay_on_collection: this.pay_on_collection,
			discount_applied: pointsApplied + rewardCost,
			business_location_id: restaurant.business_location_id,
			collection_time: typeof collection_time === 'number' ? collection_time * 1000 : collection_time,
			mobile: getConfig().flags.hasMobileCountryCode ? `${mobile_code}${mobile_value}` : mobile,
			currency: this.getSelectedCurrency(),
			order_type,
			delivery_option,
			delivery_address,
			pick_up_point,
			is_gift,
			delivery_price: new BigNumber(delivery_price).times(100).toNumber(),
			// properties for delete (later we will calculate total on BO)
			_total: total,
			total: Math.ceil(new BigNumber(total).times(100).toNumber()), //cents
			min_order,
			delivery_min_order,
			allergen_data,
			cutoffTime: cutoffTime * 1000,
			table_name: table_name || null,
			service_charge_percentage: service_percentage,
			_service_charge_value: service_charge,
			service_charge_value: new BigNumber(service_charge).times(100).toNumber(), //cents,
			passed_order,
			process_fee_value: new BigNumber(process_fee_value).times(100).toNumber(),
			is_asap,
			processing_fee_description: this.processing_fee_description,
			menu_id: this.menu,
			already_paid: new BigNumber(already_paid).times(100).toNumber(),
			bill_data: bill_data || {},
			portion_to_pay: portion_to_pay || 0,
			amount_left_to_pay: amount_left_to_pay || 0,
			table_pay_method: table_pay_method || 0,
			bill_total: bill_total || 0,
			table_items: table_items || [],
			paid_amount: paid_amount || [],
			table_members: table_members || 0,
			table_members_paid: table_members_paid || 0,
			table_payment_amount: table_payment_amount || 0,
			table_members_to_pay: table_members_to_pay || 0,
			paid_items: paid_items || [],
			table_items_price: table_items_price || 0,
			selected_items: selected_items || [],
			bill_pay_service_charge: bill_pay_service_charge || 0,
			bill_pay_service_charge_applied: bill_pay_service_charge_applied || 0,
			bill_pay_total: bill_pay_total || 0,
			restaurant_delivery_price,
			custom_field,
			uuid,
			gift_voucher_data,
			has_multi_basket,
			multi_basket_items,
			additional_data,
			tax_breakdown,
			total_tax,
			validation_id,
			channel_link_id,
			channel_name,
			send_to_deliverect,
		};
	};

	// ORDER HISTORY RELATED METHODS ----------------------------------------------------------------------------------------

	recreateOrder = (orderFromHistory) => {
		if (orderFromHistory) {
			const {
				items,
				payment_token,
				mobile,
				collection_time,
				discount_applied,
				discount,
				delivery_price,
				process_fee_value,
				delivery_address,
				applied_vouchers,
				table_name,
				service_charge_percentage,
				service_charge_value,
				is_gift,
				already_paid,
				bill_data,
				portion_to_pay,
				amount_left_to_pay,
				table_pay_method,
				bill_total,
				table_items,
				paid_amount,
				table_members,
				table_members_paid,
				table_payment_amount,
				table_members_to_pay,
				paid_items,
				table_items_price,
				selected_items,
				bill_pay_service_charge,
				bill_pay_service_charge_applied,
				bill_pay_total,
				restaurant_delivery_price,
				custom_field,
				uuid,
				gift_voucher_data,
				has_multi_basket,
				multi_basket_items,
				additional_data,
				tax_breakdown,
				total_tax,
				validation_id,
				channel_link_id,
				channel_name,
				send_to_deliverect,
				applied_gift_cards
			} = orderFromHistory;
			this.items = items || [];
			this.applied_vouchers = applied_vouchers || [];
			this.applicable_vouchers = applied_vouchers || [];
			this.applied_gift_cards = applied_gift_cards || [];
			this.selectedCard = payment_token || '';
			this.mobile = mobile;
			this.collection_time = collection_time;
			this.pointsApplied = discount_applied ?? discount;
			this.order_type = this.getOrderType(orderFromHistory);
			this.delivery_price = !isDefined(delivery_price) || delivery_price === 0 ? 0 : new BigNumber(delivery_price).div(100).toNumber();
			this.process_fee_value = new BigNumber(process_fee_value).div(100).toNumber();
			this.process_fee_amount = process_fee_value ?? 0;
			this.delivery_address = delivery_address;
			// probably will need table_order here as well
			this.table_name = table_name;
			this.orderFromHistory = orderFromHistory;
			this.service_percentage = service_charge_percentage;
			this.service_charge_value = BigNumber(service_charge_value).div(100).toNumber();
			this.bill_data = bill_data || {};
			this.portion_to_pay = portion_to_pay || 0;
			this.amount_left_to_pay = amount_left_to_pay || 0;
			this.table_pay_method = table_pay_method || 0;
			this.bill_total = bill_total || 0;
			this.table_items = table_items || [];
			this.paid_amount = paid_amount || [];
			this.table_members = table_members || 0;
			this.table_members_paid = table_members_paid || 0;
			this.table_payment_amount = parseInt(table_payment_amount) || 0;
			this.table_members_to_pay = table_members_to_pay || 0;
			this.paid_items = paid_items || [];
			this.table_items_price = table_items_price || 0;
			this.selected_items = selected_items || [];
			this.bill_pay_service_charge = bill_pay_service_charge || 0;
			this.bill_pay_service_charge_applied = bill_pay_service_charge_applied || 0;
			this.bill_pay_total = bill_pay_total || 0;
			this.has_multi_basket = has_multi_basket;
			this.multi_basket_items = multi_basket_items;
			this.additional_data = additional_data;
			this.total_tax = total_tax;
			this.tax_breakdown = this.additional_data.tax_breakdown || [];
			this.calculateSubTotal();
			this.calculateTotal();
			this.calculateBillPaymentAmount();
			this.restaurant_delivery_price = restaurant_delivery_price;
			this.custom_field = custom_field;
			this.uuid = uuid;
			this.gift_voucher_data = gift_voucher_data;
			this.setGift(gift_voucher_data, false);
			this.is_gift = is_gift;
			this.validation_id = validation_id;
			this.channel_link_id = channel_link_id;
			this.channel_name = channel_name;
			this.send_to_deliverect = send_to_deliverect;
			this.instanceStateChanged(false, true);
		}
	};
	parseBasketForMultiBasketCheckVouchers = () => {
		return {
			multi_basket_items: this.multi_basket_items.map((el) => ({
				...el,
				restaurant: {
					name: el.restaurant.name,
					id: el.restaurant.id,
					business_location_id: el.restaurant.business_location_id,
					menu_id: el.restaurant.menu_id,
				},
				total: el.items.reduce((acc, obj) => acc + this.calculateItemPrice(obj, true), 0),
			})),
			total: new BigNumber(this.total).minus(this.calculateAppliedVocuhersPrice(true)).plus(this.calculatePointsAppliedPrice()).toNumber(),
			vouchersType: vouchersType,
		};
	};
	parseBasketForCheckVouchers = () => {
		const business_location_id = this.restaurant ? this.restaurant.business_location_id : null;
		const menu_id = this.restaurant ? this.restaurant.menu_id : null;
		const parsedDiscountData = {
			items: this.items.map((item) => {
				const selectedSubItems = [];
				item.selectedChoices.forEach((selectedChoiceGroup) => {
					selectedChoiceGroup.forEach((item) => selectedSubItems.push(item));
				});
				let parsedItem = {
					qty: item.quantity,
					productPrice: isDefined(item.item.productPrice) ? item.item.productPrice : '0.00',
					name: isDefined(item.item.productName) ? item.item.productName : '',
					sku: isDefined(item.item.sku) ? item.item.sku : '',
					sub_items: selectedSubItems.map((selectedSubItem) => {
						return {
							productPrice: selectedSubItem.productPrice,
							sku: selectedSubItem.sku,
						};
					}),
				};
				if (isDefined(item.item.sku)) {
					parsedItem.sku = item.item.sku;
				}

				return parsedItem;
			}),
			total: new BigNumber(this.total).minus(this.calculateAppliedVocuhersPrice(true)).plus(this.calculatePointsAppliedPrice()).toNumber(),
			vouchersType: vouchersType,
			restaurant: {
				business_location_id: business_location_id,
				menu_id: menu_id,
			},
		};

		return parsedDiscountData;
	};

	getDate = (date) => {
		if (date && typeof date === 'string') {
			const utcOffset = moment(date).tz(getConfig().timezone).utcOffset();
			return moment(date).add(utcOffset, 'minutes');
		} else {
			return moment().tz(getConfig().timezone);
		}
	};

	formatOrderTime = (flag, format = null) => {
		let time = null;
		if (flag) {
			time = this.collection_time ? this.getDate(this.collection_time).format(format ? format : 'ddd DD MMMM YYYY [at] LT') : '';
		} else {
			time = this.collection_time ? this.getDate(this.collection_time).format(format ? format : 'ddd DD MMMM YYYY [at] LT') : '';
		}
		if (time.indexOf('pm') !== -1) {
			time = time.replace(/ pm/g, '\u00A0pm');
		} else if (time.indexOf('PM') !== -1) {
			time = time.replace(/ PM/g, '\u00A0PM');
		}
		if (time.indexOf('am') !== -1) {
			time = time.replace(/ am/g, '\u00A0am');
		} else if (time.indexOf('AM') !== -1) {
			time = time.replace(/ AM/g, '\u00A0AM');
		}
		return time;
	};

	formatPaymentMethod = (cards = [], __, orderCompletePage) => {
		let paymentMethod = '';
		const paymentType = getConfig().payment;
		if (paymentType === 'judopay') {
			const usedCard = cards.find((card) => card.cardToken === this.selectedCard);
			if (usedCard) {
				const { cardType, cardLastFour } = usedCard;
				paymentMethod = orderCompletePage ? `${cardType}  **** ${cardLastFour}` : `${cardType}  **** ${cardLastFour}`;
			}
			return paymentMethod;
		} else {
			const usedCard = cards.find((card) => card.id === this.selectedCard);
			if (usedCard) {
				const { brand, last4 } = usedCard;
				paymentMethod = orderCompletePage ? `${brand}  **** ${last4}` : `${brand}  **** ${last4}`;
			} else {
				if (this.orderFromHistory && ['Apple Pay', 'apple'].indexOf(this.orderFromHistory.payment_token) !== -1) {
					paymentMethod = 'Apple Pay';
				} else if (this.orderFromHistory && ['Google Pay', 'google'].indexOf(this.orderFromHistory.payment_token) !== -1) {
					paymentMethod = 'Google Pay';
				} else if (this.orderFromHistory && ['Pay on collection', 'collectedPay'].indexOf(this.orderFromHistory.payment_token) !== -1) {
					paymentMethod = 'Pay on collection';
				} else if (this.orderFromHistory && ['Pay to the driver', 'driverPay'].indexOf(this.orderFromHistory.payment_token) !== -1) {
					paymentMethod = 'Pay to the driver';
				}
			}
			return paymentMethod;
		}
	};

	toastMessage = (message = '', type = 'warning') => store.dispatch(showToast(message, type));

	_isCollectionTimeStillValid = (applyActions = true) => {
		if (enableCollectionTimeValidation && this.collection_time && !this.table_name && !this.is_asap) {
			const collection_time = this.collection_time * 1000;
			const currentDT = moment().tz(getConfig().timezone);
			const currentTime = currentDT.toDate().getTime();
			if (collection_time < currentTime) {
				if (applyActions) {
					if (moment(collection_time).tz(getConfig().timezone).toDate().getDate() !== currentDT.toDate().getDate()) {
						this.reset();
					} else {
						if (!this.has_multi_basket && this.order_type == 'collection') {
							store.dispatch({ type: SET_COMMON_MODAL, modal: 'changeCollectionTimeModalOpen', value: true });
						} else {
							this.reset();
							forwardTo('/click-and-collect');
							this.log(errorMessages.basketNotValid);
						}
					}
				}

				return false;
			}
		}
		return true;
	};

	isCollectionTimeStillValid = () => this._isCollectionTimeStillValid(false);

	flattenMenuItems = (menu) => {
		let flatMenu = [];

		menu.forEach((item) => {
			if (item.menuEntry && item.menuEntry.length > 0) {
				flatMenu.push(...this.flattenMenuItems(item.menuEntry));
			} else {
				if (item.sku) {
					flatMenu.push(item);
				}
				return;
			}
		});

		return flatMenu;
	};

	calculateDeliveryCharge = (total) => {
		const restaurant = this.getRestaurant();
		const deliveryChargeisPercent = restaurant?.delivery_charge_in_percent;
		let deliveryPrice = this.delivery_price;
		let restaurantDeliveryPrice = this.restaurant_delivery_price;
		if (deliveryChargeisPercent && restaurantDeliveryPrice) {
			deliveryPrice = total * (restaurantDeliveryPrice / 100);
			deliveryPrice = deliveryPrice.toFixed(2);
		}
		this.delivery_price = deliveryPrice;
		return deliveryPrice;
	};
}

export const createNewBasketInstance = () => new Basket();

export default new Basket();

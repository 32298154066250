import React from 'react';
import { connect } from 'react-redux';
import { IonItem, IonButton, IonAlert, IonCol, IonGrid, IonRow } from '@ionic/react';
import Basket from '../../lib/basket';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { NormalText, Spacer, Subtitle } from '../../components/common';
import './index.css';
import Loading from '../../components/spinner';
import { SET_TABLE_PAY_METHOD } from '../../store/constants';
import TableItemsOverview from '../../components/tableItemsOverview';
import TableBillServiceCharge from '../../components/tableBillServiceCharge';
import { forwardTo, isEmptyObject } from '../../lib/utils';
import BillPayAmount from '../../components/billPayAmount';
import CheckoutPay from '../checkout/CheckoutPay';
import BigNumber from 'bignumber.js';

const { SelectOption } = Mobiscroll;

class SplitBill extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tableMembersToPay: Basket.table_members_to_pay || 0,
			serviceChargeValue: Basket.bill_pay_service_charge || 0,
			isSetPayMethodModalOpen: false,
		};
	}

	setTablePayMethod = () => {
		Basket.setBillPayServiceCharge(0);
		Basket.setPortionToPay(0);
		this.props.dispatch({
			type: SET_TABLE_PAY_METHOD,
			data: {
				pay_method: 2,
				table_bill_id: Basket.bill_data.id,
			},
		});
	};
	componentDidMount() {
		const { google } = window;
		if (isEmptyObject(Basket.bill_data)) {
			Basket.reset();
			forwardTo('/table-bill-pay');
			return;
		}
		if (Basket.table_members_to_pay == 0) {
			Basket.setPortionToPay(1);
			this.setState({ tableMembersToPay: 1 });
		}
		if (isEmptyObject(Basket.getRestaurant()) || !Basket.getRestaurant()) {
			const selectedRestaurant = this.props.restaurants.find((restaurant) => restaurant.name == Basket.bill_data.restaurant_name);
			Basket.setRestaurant(selectedRestaurant);
		}
		// if (google) {
		//   window.googlePayClient = new google.payments.api.PaymentsClient({
		//     environment: 'PRODUCTION',
		//   });
		// }
	}
	selectPortion = (event, data) => {
		const tableMembersToPay = data.getVal();
		Basket.setPortionToPay(tableMembersToPay);
		this.setState({ tableMembersToPay });
	};
	formatDataForSelect = () => {
		let billMembers = 0;
		if (Basket.table_members_paid == 0) {
			billMembers = Basket.getTableMembers();
		} else {
			billMembers = Basket.table_members - Basket.table_members_paid;
		}
		const { __ } = this.props;
		let arrForSelect = [{ text: __(`Select no. of people`), value: 0 }];
		for (let i = 1; i <= billMembers; i++) {
			arrForSelect.push({
				text: `${__(`${i}`)} ${i == 0 ? __(`person`) : __(`people`)} - ${this.calculatePortionToPay(i, i === billMembers)}`,
				value: i,
			});
		}
		return arrForSelect;
	};
	calculatePortionToPay = (members, isLastPortion = false) => {
		const totalMembers = Basket.getTableMembers();
		const billTotal = Basket?.bill_total;
		if (isLastPortion && Basket?.amount_left_to_pay) {
			return Basket.formatPrice(parseInt(Basket.round(new BigNumber(Basket.amount_left_to_pay).toNumber(), 2)) / 100);
		}
		return Basket.formatPrice(parseInt(Basket.round(new BigNumber(billTotal).div(totalMembers).times(members).toNumber(), 2)) / 100);
	};
	onServiceChargeSetHandler = (serviceChargeValue) => {
		this.setState({ serviceChargeValue });
	};
	backHandler = () => {
		Basket.reset();
		forwardTo('/table-bill-pay');
	};
	render() {
		const { __, clientSecret, profile, dispatch, location, isTableItemsOverviewVisible } = this.props;
		const { serviceChargeValue, tableMembersToPay } = this.state;
		const deliveryOption = Basket.getDeliveryOption();
		return (
			<Loading>
				<Layout backHandler={this.backHandler} headerWithTitle title={`${__('Table')} ${Basket.getBillTableNumber()}`} color="transparent">
					<div className="absolute-content scrollable-y">
						<div className="bill-pay-title-wrapper">
							<NormalText className="block">
								{__('Table')} {Basket.getBillTableNumber()}
							</NormalText>
							<IonButton fill="clear" color="secondary" className="link underlined" onClick={() => forwardTo('/table-bill-pay')}>
								{__('Wrong table?')}
							</IonButton>
						</div>
						<Spacer size={1} />
						<BillPayAmount />
						<Spacer size={1} />
						<TableItemsOverview />

						<Spacer size={1} />
						<div className="table-members-wrapper">
							<div>
								<NormalText>{__('Split between:')}</NormalText>
								<IonItem lines="none" className="box-wrapper">
									<SelectOption
										required={true}
										type="number"
										pattern="number"
										inputmode="number"
										value={Basket.getTableMembers()}
										data={[
											{
												value: Basket.getTableMembers(),
												text: `${Basket.getTableMembers()} ${__('people')}`,
											},
										]}
										disabled={true}
									/>
								</IonItem>
							</div>
							<Spacer size={1} />
							<div>
								<NormalText>{__('I will pay for:')}</NormalText>
								<div className={`box-wrapper ${this.state.tableMembersToPay == 0 ? 'required-field' : ''}`}>
									<SelectOption
										display="center"
										onSet={(e, a) => this.selectPortion(e, a)}
										data={this.formatDataForSelect()}
										value={Basket.table_members_to_pay}
										placeholder={__('Select Potion')}
										disabled={Basket.getTableMembers() ? false : true}
									/>
								</div>
							</div>
						</div>
						<Spacer size={1} />
						<TableBillServiceCharge tableMembers={tableMembersToPay} serviceChargeSet={this.onServiceChargeSetHandler} />
						<Spacer size={1} />
						{(serviceChargeValue > 0 || tableMembersToPay > 0) && (
							<>
								<IonGrid className="box-wrapper space-between pointer ">
									<IonRow>
										<IonCol>
											<Subtitle className="bold">{__('Payment amount')}</Subtitle>
										</IonCol>
										<IonCol className="righted">
											<Subtitle className="bold">{Basket.getTotalPlusServiceCharge()}</Subtitle>
										</IonCol>
									</IonRow>
								</IonGrid>
								<Spacer size={1} />
							</>
						)}

						{this.state.tableMembersToPay > 0 && (
							<CheckoutPay clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} isBillPay={true} billTotal={Basket.bill_pay_total} />
						)}

						<Spacer size={1} />

						<IonButton
							disabled={this.state.tableMembersToPay == 0}
							color="primary"
							expand="block"
							onClick={() => (this.state.tableMembersToPay != 0 ? forwardTo('/checkout', { fromTablePay: true }) : null)}
						>
							{__('Pay with card')}
						</IonButton>
						<IonButton expand="block" fill="clear" color="primary" className="link underlined" onClick={() => this.setState({ isSetPayMethodModalOpen: true })}>
							{__('Or pay custom amount')}
						</IonButton>
					</div>
				</Layout>
				<IonAlert
					isOpen={this.state.isSetPayMethodModalOpen}
					onDidDismiss={() => this.setState({ isSetPayMethodModalOpen: false })}
					header={__('Are you sure that you want to set this as your chosen payment method?')}
					// message={__(this.getRestaurantDataMessage(__))}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.setState({ isSetPayMethodModalOpen: false }),
						},
						{
							text: __('Confirm'),
							role: 'submit',
							cssClass: 'secondary',
							handler: () => this.setTablePayMethod(),
						},
					]}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	const { restaurants, tableNumbers, tableData } = store.restaurants;
	const { myLocation, isTableItemsOverviewVisible } = store.common;
	const { clientSecret } = store.orders;
	const { auth, profile } = store.profile;

	return {
		restaurants: restaurants || [],
		tableNumbers: tableNumbers || {},
		myLocation,
		tableData,
		profile,
		clientSecret,
		auth,
		isTableItemsOverviewVisible,
	};
};

export default connect(mapStateToProps)(withTranslation(SplitBill));
